export namespace SolanaProgram {
  // System / Sysvar programs
  // https://docs.solana.com/developing/runtime-facilities/sysvars
  export const SYSTEM = "11111111111111111111111111111111";
  export const NULL = "11111111111111111111111111111111";
  export const CONFIG = "Config1111111111111111111111111111111111111";
  export const STAKE = "Stake11111111111111111111111111111111111111";
  export const STAKE_HISTORY = "SysvarStakeHistory1111111111111111111111111";
  export const STAKE_CONFIG = "StakeConfig11111111111111111111111111111111";
  export const RENT = "SysvarRent111111111111111111111111111111111";
  export const CLOCK = "SysvarC1ock11111111111111111111111111111111";
  export const SLOT_HISTORY = "SysvarS1otHistory11111111111111111111111111";
  export const SLOT_HASHES = "SysvarS1otHashes111111111111111111111111111";
  export const INCINERATOR = "1nc1nerator11111111111111111111111111111111";
  export const VOTE = "Vote111111111111111111111111111111111111111";
  export const COMPUTE_BUDGET = "ComputeBudget111111111111111111111111111111";

  // Defi
  export const JUPITER_AGGREGATOR_V2 =
    "JUP2jxvXaqu7NQY1GmNF4m1vodw12LVXYxbFL2uJvfo";
  export const JUPITER_AGGREGATOR_V3 =
    "JUP3c2Uh3WA4Ng34tw6kPd2G4C5BB21Xo36Je1s32Ph";
  export const JUPITER_AGGREGATOR_V4 =
    "JUP4Fb2cqiRUcaTHdrPC8h2gNsA2ETXiPDD33WcGuJB";
  export const MANGO_ACC_1 = "98pjRuQjK3qA6gXts96PqZT4Ze5QmnCmt3QYjhbUSPue";
  export const MANGO_ACC_2 = "EBDRoayCDDUvDgCimta45ajQeXbexv7aKqJubruqpyvu";
  export const MANGO_ACC_3 = "GDDMwNyyx8uB6zrqwBFHjLLG3TBYk2F8Az4yrQC5RzMp";
  export const MANGO_PROGRAM_3 = "mv3ekLzLbnVPNxjSKvqBpU3ZeZXPQdEC3bp5MDEBG68";
  export const OTTER = "otterXYtgZ5DRUGX6JGtcZPg3GoWxEqcLrb9MjeCv3X";
  export const PYTH_ACC_1 = "Ax9ujW5B9oqcv59N8m6f1BpTBq2rGeGaBcpKjC5UYsXU";
  export const PYTH_ORACLE = "FsJ3A3u2vn5cTVofAjvy6y5kwABJAqYWpe4975bi2epH";
  export const RAYDIUM_LIQUIDITY_POOL =
    "675kPX9MHTjS2zt1qfr1NYHuzeLXfQM9H24wFSUt1Mp8";
  export const SERUM_DEX = "9xQeWvG816bUx9EPjHmaT23yvVM2ZWbrrpZb9PusVFin";
  export const SWITCH_DEFI = "SW1TCH7qEPTdLsDHRgPuMQjbQxKdH2aBStViMFnt64f";
  export const SWITCHBOARD_ORACLE =
    "DtmE9D2CSB4L5D6A15mraeEjrGMm6auWVzgaD8hK2tZM";
  export const UNKNOWN_DEFI_1 = "cjg3oHmg9uuPsP8D6g29NWvhySJkdYdAo9D25PRbKXJ";
  export const UNKNOWN_DEFI_2 = "5JQ8Mhdp2wv3HWcfjq9Ts8kwzCAeBADFBDAgBznzRsE4";
  export const UNKNOWN_DEFI_3 = "9723cqpsBZN1sAP9QH9FoQBD93wgxRVqyfk7XPiEUU6k";
  export const UNKNOWN_DEFI_4 = "GCQ2KPaxKeweMdHcJtfdFd88o1mQvntA1oPSBkSfJwQp";
  export const ZETA = "ZETAxsqBRek56DhiGXrn75yj2NHU3aYUnxvHXpkf3aD";
  export const ZODEX = "Zo1ggzTUKMY5bYnDvT5mtVeZxzf2FaLTbKkmvGUhUQk";

  export const SQUADS = "SMPLecH534NA9acpos4G6x7uf3LWbCAwZQE9e8ZekMu";

  // https://sharky.fi/
  export const SHARKY = "SHARKobtfF1bHhxD2eqftjHBdVSCbKo9JtgK71FhELP";

  // SPL Programs
  export const TOKEN = "TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA";
  export const TOKEN_SWAP = "SwaPpA9LAaLfeLi3a68M4DjnLqgtticKg6CnyNwgAC8";
  export const ASSOCIATED_TOKEN =
    "ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL";
  export const MEMO = "MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr";

  // Metaplex Programs
  export const TOKEN_METADATA = "metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s";
  export const CANDY_MACHINE_V1 = "cndyAnrLdpjq1Ssp1z8xxDsB8dxe7u4HL5Nxi2K5WXZ";
  export const CANDY_MACHINE = "cndy3Z4yapfJBmL3ShUp5exZKqR3z33thTzeNMm2gRZ";

  export const WRAPPED_SOL = "So11111111111111111111111111111111111111112";
  export const USDC_MINT = "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v";

  // Glow Programs
  export const NOTE = "noteD9tEFTDH1Jn9B1HbpoC7Zu8L9QXRo7FjZj3PT93";

  export const GLOW_ID = "GLoW6kDXmQHFjtQQ44ccmXjosqrKkE54bVbUTA4bA3zs";
  export const GLOW_ID_AUTH = "au19QGSfERnBsa9ep6y29PFgNhVLaeSKiCdETotMnm1";
  export const GLOW_ID_FEE_DEPOSIT =
    "fe1eU7F8Y9VJWg4XVnAXPtJVq7sdNZEtckAYaESbmjx";

  export const NFTOKEN = "nftokf9qcHSYkVSP3P2gUMmV6d4AwjMueXgUu43HyLL";

  // This is their custom program which allows users to set custom fees
  export const SOLANART_V1 = "CJsLwbP1iu5DuUikHEJnLfANgKy6stB2uFgvBBHoyxwz";
  export const SOLANART_V1_CUSTODY =
    "3D49QorJyNaL4rcpiynbuS3pRH4Y7EXEM6v6ZGaqfFGK";
  export const SOLANART_V1_FEE = "39fEpihLATXPJCQuSiXLUSiCbGchGYjeL39eyXh3KbyT";

  // TODO: what does stake mean?
  export const SOLANART_V1_STAKE =
    "7gDpaG9kUXHTz1dj4eVfykqtXnKq2efyuGigdMeCy74B";

  export const STEPN = "STEPNq2UGeGSzCyGVr2nMQAzf8xuejwqebd84wcksCK";

  export const MAGIC_EDEN_1_PROGRAM =
    "MEisE1HzehtrDpAAT8PnLHjpSSkRYakotTuJRPjTpo8";
  export const MAGIC_EDEN_FEE = "2NZukH2TXpcuZP4htiuT8CFxcaQSWzkkR6kepSWnZ24Q";
  // This account stores tokens that are for listing on the MagicEden site
  export const MAGIC_EDEN_CUSTODY =
    "GUfCR9mK6azb9vcpsxgXyj7XRPAKJd4KMHTTVvtncGgp";

  export const MAGIC_EDEN_2_PROGRAM =
    "M2mx93ekt1fmXSVkTrUL9xVFHkmME8HTUi5Cyc5aF7K";
  export const MAGIC_EDEN_2_AUCTION_HOUSE =
    "E8cU1WiRWjanGxmn96ewBgk9vPTcL6AEZ1t6F6fkgUWe";
  export const MAGIC_EDEN_2_REFERRAL =
    "autMW8SgBkVYeBgqYiTuJZnkvDZMVU2MHJh9Jh7CSQ2";
  export const MAGIC_EDEN_2_FEE = "rFqFJ9g7TGBD8Ed7TPDnvGKZ5pWLPDyxLcvcH2eRCtt";
  // This is the delegate authority for token accounts
  export const MAGIC_EDEN_2_CUSTODY =
    "1BWutmTvYPwDtmw9abTkS4Ssr8no61spGAvW1X6NDix";

  export const FORMFN_PROGRAM = "formn3hJtt8gvVKxpCfzCJGuoz6CNUFcULFZW18iTpC";

  export const HYPERSPACE_PROGRAM =
    "HYPERfwdTjyJ2SCaKHmpF2MtrXqWxrsotYDsTrshHWq8";
  export const HYPERSPACE_DELEGATE =
    "FntWf1qzpgWn9eLpkC5ow1gyiKjX2LsmmemrvsgpBYUT";
  export const HYPERSPACE_AUTHORITY =
    "FEL1Z3EjUEbET9miT2p3S8qK1K11stCzN5KLaqZZ976d";
  export const HYPERSPACE_AUCTION_HOUSE =
    "5pdaXth4ijgDCeYDKgSx3jAbN7m8h4gy1LRCErAAN1LM";
  export const HYPERSPACE_FEE = "4BzVBkBvZ7atwQCw2sVQjXG3EDuouuD7EVgtEgJ9CNWc";

  export const GLOW_HYPERSPACE_AFFILIATE =
    "hyp9546y4wjXdjStET2gH4ShHGzurtKZpU2wUoxotsS";

  export const MARINADE_PROGRAM = "MarBmsSgKXdrN1egZf5sqe1TMai9K1rChYNDJgjq7aD";

  /**
   * Rarible, Solanart, Fractal, and Opensea are all using a shared Auction Haus Program that is
   * owned and developed by Metaplex.
   */
  export const AUCTION_HAUS_PROGRAM =
    "hausS13jsjafwWwGqZTUQRmWyvyxn9EQpqMwV1PBBmk";
  export const AUCTION_HAUS_CUSTODY =
    "HS2eL9WJbh7pA4i4veK3YDwhGLRjY3uKryvG1NbHRprj";

  export const OPENSEA_AUCTION_HAUS_CONFIG =
    "3o9d13qUvEuuauhFrVom1vuCzgNsJifeaBYDPquaT73Y";
  export const SOLANART_AUCTION_HAUS_CONFIG =
    "GWErq8nJf5JQtohg5k7RTkiZmoCxvGBJqbMSfkrxYFFy";
  export const FRACTAL_AUCTION_HAUS_CONFIG =
    "BAmKB58MgkeYF2VueVBfASL5q8Qf6VKp4nA4cRuVUVft";

  export const AUCTION_HAUS_CONFIGS = [
    OPENSEA_AUCTION_HAUS_CONFIG,
    SOLANART_AUCTION_HAUS_CONFIG,
    FRACTAL_AUCTION_HAUS_CONFIG,
  ];

  export const RECENT_BLOCKHASHES_SYSVAR =
    "SysvarRecentB1ockHashes11111111111111111111";

  export const ACCOUNTS_TO_NOT_INDEX = new Set([
    CANDY_MACHINE,
    CANDY_MACHINE_V1,
    CLOCK,
    JUPITER_AGGREGATOR_V2,
    JUPITER_AGGREGATOR_V3,
    JUPITER_AGGREGATOR_V4,
    MANGO_ACC_1,
    MANGO_ACC_2,
    MANGO_ACC_3,
    MANGO_PROGRAM_3,
    MEMO,
    OTTER,
    PYTH_ACC_1,
    PYTH_ORACLE,
    RAYDIUM_LIQUIDITY_POOL,
    RENT,
    SERUM_DEX,
    SWITCH_DEFI,
    SWITCHBOARD_ORACLE,
    UNKNOWN_DEFI_1,
    UNKNOWN_DEFI_2,
    UNKNOWN_DEFI_3,
    UNKNOWN_DEFI_4,
    VOTE,
    ZETA,
    ZODEX,
  ]);

  // I'm not sure what this does but I've seen it on transfers like this one:
  // https://solscan.io/tx/39aR4FzfeFDc1Xkosu4kFosNjLoUaCHcGQsFLAEiocBo5Dcw59PyoVovTrLDmVGp5YkxCozem7vAi2MJHAitrER9
  export const PHANTOM_PROGRAM = "DeJBGdMFa1uynnnKiwrVioatTuHmNLpyFKnmB5kaFdzQ";

  export const FTX_PROGRAM = "4MNPdKu9wFMvEeZBMt3Eipfs5ovVWTJb31pEXDJAAxX5";
  export const FTX_WALLET = "6ZRCB7AAqGre6c72PRz3MHLC73VMYvJ8bi9KHf1HFpNk";

  // Not really a program, but rather a pubkey
  export const VALIDATOR_PUBKEY = "Va1idator1nfo111111111111111111111111111111";

  export const MARKETPLACE_PROGRAMS: string[] = [
    SOLANART_V1,
    HYPERSPACE_PROGRAM,
    MAGIC_EDEN_1_PROGRAM,
    MAGIC_EDEN_2_PROGRAM,
    AUCTION_HAUS_PROGRAM,
  ];
}
