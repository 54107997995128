import {
  LuxButton,
  LuxButtonProps,

} from "@lux/atoms/components/LuxButton";
import { LuxTooltip } from "@lux/atoms/components/LuxTooltip";
import React from "react";
import { LuxSubmitButton, LuxSubmitButtonProps } from "@lux/atoms/components/LuxSubmitButton";

export type GlowButtonProps = LuxButtonProps & {
  hideTooltipForIconOnly?: boolean;
};

export const GlowButton = React.forwardRef(
  ({ hideTooltipForIconOnly, ...props }: GlowButtonProps, ref) => {
    const wrapInTooltip =
      props.iconPlacement === "icon-only" && !hideTooltipForIconOnly;

    if (!wrapInTooltip) {
      return <LuxButton ref={ref} {...props} />;
    }

    return (
      <div ref={ref as any} className={props.className}>
        <LuxTooltip
          content={props.label}
          forceHidden={props.loading || props.disabled}
        >
          <LuxButton
            {...props}
            className={wrapInTooltip ? undefined : props.className}
          />
        </LuxTooltip>
      </div>
    );
  }
);

export const GlowSubmitButton = (props: LuxSubmitButtonProps) => {
  return <LuxSubmitButton {...props} />;
};
