import { useEffect, useState } from "react";
import { useIsMounted } from "./useIsMounted";

export const useMediaQuery = (media: string): boolean => {
  // We check if the component is mounted since media queries will always
  // evaluate to false when SSR'd
  const isMounted = useIsMounted();

  const [isMatching, setIsMatching] = useState<boolean>(
    (typeof window === "object" && window.matchMedia?.(media).matches) || false
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia?.(media);
    const listener = (event: MediaQueryListEvent) =>
      setIsMatching(event.matches);

    if (mediaQuery?.addEventListener) {
      mediaQuery.addEventListener("change", listener);
    } else if (mediaQuery?.addListener) {
      mediaQuery.addListener(listener);
    }

    return () => {
      /* @ts-ignore */
      if (mediaQuery?.removeEventListener) {
        mediaQuery.addEventListener("change", listener);
      } else if (mediaQuery?.removeListener) {
        mediaQuery.removeListener(listener);
      }
    };
  }, [media, setIsMatching]);

  return isMatching && isMounted;
};
