import base from "./base.json";
import dev from "./dev.json";
import prod from "./prod.json";
import staging from "./staging.json";

type Config = {
  apiUrl: string;
  cookieDomain: string;
  cryptoStripePublicKey: string;
  stripePublicKey: string;
  webUrl: string;
};

const isProd = process.env.NODE_ENV === "production";
let envConfig = isProd ? prod : dev;

const isStaging = process.env.NEXT_PUBLIC_IS_STAGING === "true";
if (isStaging) {
  envConfig = staging;
}

const config: Config = Object.assign(base, envConfig);

export default config;
