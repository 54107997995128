import axios, { AxiosError, Cancel } from "axios";
import toast from "react-hot-toast";
import { ReportErrorOptions } from "@lux/atoms/utils/sentry";
import {
  DEFAULT_TOAST_STYLE,
  ERROR_TOAST_STYLE,
  SUCCESS_TOAST_STYLE,
} from "@lux/atoms/utils/toast";

type ValueFunction<TValue, TArg> = (arg: TArg) => TValue;
type ValueOrFunction<TValue, TArg> = TValue | ValueFunction<TValue, TArg>;

const isFunction = <TValue, TArg>(
  valOrFunction: ValueOrFunction<TValue, TArg>
): valOrFunction is ValueFunction<TValue, TArg> =>
  typeof valOrFunction === "function";

const resolveValueOrFunction = <TValue, TArg>(
  valOrFunction: ValueOrFunction<TValue, TArg>,
  arg: TArg
): TValue => (isFunction(valOrFunction) ? valOrFunction(arg) : valOrFunction);

export const toastLoading = (
  message: string | JSX.Element,
  id?: string | null
): string => {
  return toast.loading(message, {
    id: id ?? undefined,
    ...DEFAULT_TOAST_STYLE,
  });
};

export const createCallWithToast = <
  ExtraErrorOptions extends Record<string, unknown> = never
>({
  reportError,
}: {
  reportError: (
    message: string,
    options: ReportErrorOptions & ExtraErrorOptions
  ) => unknown;
}) => {
  /**
   * Call an API endpoint with toast notification and error reporting.
   *
   * @param call A promise to be awaited or a function that generates a promise.
   * @param msgs Messages for loading, success and error. If no loading message
   *   specified, then the toast is not shown in loading state.
   * @param errorOpts Options for error reporting. If no sentry message specified,
   *   the error will not be logged to sentry.
   */
  const callWithToast = async <T>(
    call: Promise<T> | (() => Promise<T>),
    msgs: {
      loading?: string;
      success?: ValueOrFunction<string, T>;
      error: ValueOrFunction<string, any>;
    },
    errorOpts?: {
      sentryMessage?: string;
      payload?: { [key: string]: any };
    } & ExtraErrorOptions
  ): Promise<
    | { data: null; error: Error | Cancel | AxiosError }
    | { data: T; error: null }
  > => {
    let toastId = undefined;
    if (msgs.loading) {
      toastId = toast.loading(msgs.loading, DEFAULT_TOAST_STYLE);
    }

    try {
      let value;
      if (typeof call === "function") {
        value = await call();
      } else {
        value = await call;
      }

      if (msgs.success) {
        toast.success(resolveValueOrFunction(msgs.success, value), {
          id: toastId,
          ...SUCCESS_TOAST_STYLE,
        });
      } else if (toastId) {
        // Remove the loading toast
        toast.dismiss(toastId);
      }

      return { data: value, error: null };
    } catch (error) {
      // Handle the special Axios cancel case.
      if (axios.isCancel(error)) {
        toast.dismiss(toastId);
        return { data: null, error: error as Cancel };
      }

      const { sentryMessage, payload, ...extraOptions } = errorOpts || {};
      // Report a general error.
      reportError(errorOpts?.sentryMessage || "", {
        error,
        payload: errorOpts?.payload,
        // If no sentry message specified, skip sentry.
        // Otherwise, use the default sentry setting (log for 5xx).
        skipSentry: !errorOpts?.sentryMessage ? true : undefined,
        toast: {
          id: toastId,
          message: resolveValueOrFunction(msgs.error, error),
          opts: ERROR_TOAST_STYLE,
        },
        ...extraOptions,
      } as ReportErrorOptions & ExtraErrorOptions);

      return { data: null, error: error as Error };
    }
  };
  return callWithToast;
};
