export const REM_PX = 16;

// ATTENTION: If you change these values, make sure you update the breakpoints
// in .scss files too.
export enum ResponsiveBreakpoint {
  tiny = "450px",
  small = "650px",
  medium = "820px",
  large = "1000px",
  communityLargeTwoCol = "1120px",
}

export enum ResponsiveBreakpointNumbers {
  tiny = 450,
  small = 650,
  medium = 820,
  large = 1000,
  communityLargeTwoCol = 1120,
}
