import { pluralize, Solana } from "@luma-team/shared";
import { Count } from "@lux/atoms/components/Count";
import { LuxLink } from "@lux/atoms/components/LuxLink";
import { SquareNftImage } from "@glow/react/components/nfts/SquareNftImage";
import { useDebounce } from "@lux/atoms/hooks/useDebounce";
import { useOnMount } from "@lux/atoms/hooks/useOnMount";
import SearchIcon from "@lux/icons/SearchThick16.svg";
import classNames from "classnames";
import { useRouter } from "next/router";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { useZmClient } from "@components/hooks/useZmClient";
import { ResponsiveBreakpoint } from "@utils/StyleConstants";
import { SearchContext, SearchItem, SearchItemType } from "./search-types";

const SearchRow = ({
  item,
  selected,
  onMouseEnter,
  scrollIntoView,
}: {
  item: SearchItem;
  selected: boolean;
  onMouseEnter: () => void;
  scrollIntoView: (elem: HTMLAnchorElement | null) => void;
}) => {
  const { hideSearch } = useContext(SearchContext);
  const ref = useRef<HTMLAnchorElement>(null);

  let href: string;
  let imageUrl: string | null;
  let name: string;
  let rightCol: React.ReactNode | null = null;

  useEffect(() => {
    if (selected) {
      scrollIntoView(ref.current);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  if (item.type === SearchItemType.NftCollection) {
    const { collection } = item;
    href = `/collection/${collection.magic_eden_symbol}`;
    imageUrl = collection.image_url;
    name = collection.name;
    rightCol = (
      <div className="text-tertiary nowrap">
        <Count num={collection.num_nfts} />{" "}
        {pluralize("NFT", collection.num_nfts)}
      </div>
    );
  } else {
    const { nft } = item;
    href = `/token/${nft.mint_address}`;
    imageUrl = nft.image ?? null;
    name = nft.name;
  }

  return (
    <LuxLink
      ref={ref}
      className={classNames(
        "search-row flex-center spread",
        {
          selected,
        }
        // `row-${index}`
      )}
      onMouseEnter={onMouseEnter}
      href={href}
      onClick={() => hideSearch()}
    >
      <div className="flex-center">
        <SquareNftImage
          imageUrl={imageUrl}
          name={name}
          size={24}
          className="img mr-2"
          rounded
        />
        <div>{name}</div>
      </div>
      {rightCol}
    </LuxLink>
  );
};

export const SearchBody = () => {
  const { hideSearch, currentIndex, setCurrentIndex } =
    useContext(SearchContext);
  const [query, setQuery] = useState("");
  const debouncedQuery = useDebounce(query, 250);
  const router = useRouter();
  const ref = useRef<HTMLInputElement>(null);

  useOnMount(() => {
    ref.current?.focus();
  });

  const { data: collectionsData } = useZmClient({
    path: "/solana/explore/search",
    args: {
      query: debouncedQuery,
      network: Solana.Network.Mainnet,
      current_address: "",
    },
    pause: debouncedQuery.trim() === "",
  });
  const { data: nftsData } = useZmClient({
    path: "/solana/explore/search-nfts",
    args: {
      query: debouncedQuery,
      network: Solana.Network.Mainnet,
    },
    pause: debouncedQuery.trim() === "",
  });

  // TODO: figure out how to store previous result of data
  const items: SearchItem[] = useMemo(() => {
    const collections: SearchItem[] =
      collectionsData?.nft_collections.map((collection) => {
        return { collection, type: SearchItemType.NftCollection };
      }) || [];
    const nfts: SearchItem[] =
      nftsData?.nfts.map((nft) => {
        return { nft, type: SearchItemType.Nft };
      }) || [];

    return [...collections, ...nfts];
  }, [collectionsData, nftsData]);

  useEffect(() => {
    if (currentIndex >= items.length) {
      setCurrentIndex(Math.max(items.length - 1, 0));
    } else if (items.length === 0) {
      setCurrentIndex(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, setCurrentIndex]);

  // Keyboard navigation
  // TODO: pull this out into a shared hook
  const handleKeyNavigation = useCallback(
    // This function needs to be synchronous in order to properly prevent default and
    // stop propagation on the keyboard event.
    (event: KeyboardEvent): boolean => {
      if (!open) {
        return true;
      }

      switch (event.key) {
        case "ArrowDown":
        case "Down": {
          event.preventDefault();
          event.stopPropagation();
          const idx = Math.min(currentIndex + 1, items.length - 1);
          setCurrentIndex(idx);
          return false;
        }
        case "ArrowUp":
        case "Up": {
          event.stopPropagation();
          event.preventDefault();
          const idx = Math.max(currentIndex - 1, 0);
          setCurrentIndex(idx);
          return false;
        }
        case "Enter": {
          event.preventDefault();
          event.stopPropagation();

          if (currentIndex >= items.length || currentIndex < 0) {
            return false;
          }

          // Find the row and trigger the action.
          const r = items[currentIndex];
          if (r.type === SearchItemType.NftCollection) {
            router.push(`/collection/${r.collection.magic_eden_symbol}`);
          } else if (r.type === SearchItemType.Nft) {
            router.push(`/token/${r.nft.mint_address}`);
          }
          hideSearch();

          // TODO: close search

          return false;
        }
      }

      return true;
    },
    [setCurrentIndex, items, currentIndex, router, hideSearch]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyNavigation);
    return () => {
      document.removeEventListener("keydown", handleKeyNavigation);
    };
  }, [handleKeyNavigation]);

  return (
    <div>
      <input
        ref={ref}
        type="text"
        value={query}
        onChange={(e) => {
          setQuery(e.target.value);
        }}
        className="search-input"
        placeholder="Search for NFTs or collections..."
      />

      <div className="results">
        {collectionsData && nftsData && items.length === 0 && (
          <div className="no-results flex-center-center">
            <div className="icon flex-center-center">
              <SearchIcon />
            </div>
            <div className="text">No Results Found</div>
          </div>
        )}

        {items.map((item, idx) => {
          const previousItem = items[idx - 1];
          const showCategory = !previousItem || previousItem.type !== item.type;
          return (
            <>
              {showCategory && (
                <CategoryTitle key={`category-${idx}`} type={item.type} />
              )}
              <SearchRow
                key={`item-${idx}`}
                item={item}
                selected={idx === currentIndex}
                onMouseEnter={() => setCurrentIndex(idx)}
                scrollIntoView={(elem) => {
                  if (!elem) {
                    return;
                  }

                  elem.scrollIntoView({ block: "nearest", inline: "nearest" });
                }}
              />
            </>
          );
        })}
      </div>

      <style jsx>{`
        div {
          .search-input {
            all: unset;
            width: 100%;
            box-sizing: border-box;
            padding: 0.75rem 1.5rem;
            font-size: var(--font-size-xl);
          }

          .results {
            border-top: 1px solid var(--divider-color);
            max-height: 300px;
            overflow: auto;
          }

          .no-results {
            flex-direction: column;
            padding: 1rem;

            .icon {
              width: 3rem;
              height: 3rem;
              background-color: var(--tertiary-bg-color);
              color: var(--tertiary-color);
              border-radius: 100%;
              margin-bottom: 1rem;

              :global(svg) {
                width: 1.5rem;
                height: 1.5rem;
              }
            }

            .text {
              color: var(--tertiary-color);
              font-size: var(--font-size-lg);
            }
          }

          :global(.search-row) {
            padding: 0.75rem 1.5rem;
            display: flex;
            color: inherit;
            transition: none;
          }

          :global(.search-row.selected) {
            border-left: 4px solid var(--brand-color);
            background-color: var(--hover-bg-color);
          }

          :global(.search-row.selected .img) {
            margin-left: -4px;
          }

          @media (max-width: ${ResponsiveBreakpoint.tiny}) {
            .search-input,
            :global(.search-row) {
              padding: 0.5rem 1rem;
            }

            .search-input {
              font-size: var(--font-size-lg);
            }
          }
        }
      `}</style>
    </div>
  );
};

const SearchItemTypeToInfo: Record<SearchItemType, { title: string }> = {
  [SearchItemType.Nft]: { title: "NFTs" },
  [SearchItemType.NftCollection]: { title: "Collections" },
};

const CategoryTitle = ({ type }: { type: SearchItemType }) => {
  return (
    <div className="search-category">
      <span className="text-tertiary text-xs">
        {SearchItemTypeToInfo[type].title}
      </span>

      <style jsx>{`
        .search-category {
          padding: 0.25rem 1.5rem;
          display: flex;
          color: inherit;
          transition: none;
          text-transform: uppercase;
        }
      `}</style>
    </div>
  );
};
