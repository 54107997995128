import bs58 from "bs58";
import { DateTime } from "luxon";
import { sign } from "tweetnacl";
import { z } from "zod";
import { Base64, TString } from "../util/data-types";
import { SolanaProgram } from "../util/solana-program";

export namespace Solana {
  export const AddressRegex = /^[5KL1-9A-HJ-NP-Za-km-z]{32,44}$/;
  export const AddressZ = z.string().regex(AddressRegex);
  export type Address = z.infer<typeof AddressZ>;

  export const SignatureRegex = /^[5KL1-9A-HJ-NP-Za-km-z]{85,90}$/;
  export const SignatureZ = z.string().regex(SignatureRegex);
  export type Signature = z.infer<typeof SignatureZ>;

  export const DerivePathRegex = /^44'\/501'(\/\d+'){0,2}$/;
  export const DerivePathZ = z.string().regex(DerivePathRegex);
  export type DerivePath = z.infer<typeof DerivePathZ>;

  export const SYMBOL = "SOL";

  export const LAMPORTS_PER_SOL = 1_000_000_000;
  export const SOL_DECIMALS = 9;

  export const USDC_UNITS_PER_DOLLAR = 1_000_000;

  export const OPENSEA_BALANCE_MINT_ADDRESS = "opensea-sol-balance";
  export const MAGIC_EDEN_BALANCE_MINT_ADDRESS = "magic-eden-sol-balance";

  export enum NetworkHealth {
    Good = "good",
    Slow = "slow",
    Down = "down",
  }

  export enum SwapSide {
    Ask = "ask",
    Bid = "bid",
  }

  export const SolAmountZ = z.object({ lamports: z.string() });
  export type SolAmount = z.infer<typeof SolAmountZ>;

  export const TokenAmountZ = z.object({ units: z.string() });
  export type TokenAmount = z.infer<typeof TokenAmountZ>;

  export const Program = SolanaProgram;

  // lamports, fee in tx per signature
  export const BASE_TX_FEE = 5_000;

  export namespace message {
    export const signIn = ({
      appName,
      domain,
      address,
      nonce,
      requestedAt,
    }: {
      appName: string | null;
      domain: string;
      address: Solana.Address;
      nonce: number;
      requestedAt: DateTime;
    }): string => {
      const message = `${
        appName ?? domain
      } would like you to sign in with your Solana account:
      ${address}

      Domain: ${domain}
      Requested At: ${requestedAt.toUTC().toISO()}
      Nonce: ${nonce}`;

      return message
        .split("\n")
        .map((line) => line.trim())
        .join("\n");
    };

    export const parseSignIn = ({
      message,
    }: {
      message: string;
    }): {
      appName: string;
      domain: string;
      address: Solana.Address;
      nonce: string;
      requestedAt: DateTime;
    } => {
      const regexStr =
        `^(?<appName>.{0,100}) would like you to sign in with your Solana account:
        (?<address>[5KL1-9A-HJ-NP-Za-km-z]{32,44})

        Domain: (?<domain>[A-Za-z0-9.\\-]+)
        Requested At: (?<requestedAt>.+)
        Nonce: (?<nonce>[A-Za-z0-9\-\.]+)$`
          .split("\n")
          .map((s) => s.trim())
          .join("\n");
      const regex = new RegExp(regexStr);

      const match = message.match(regex);

      if (!match || !match.groups) {
        throw new Error("Invalid message format.");
      }

      const {
        appName,
        domain,
        address,
        nonce: _nonce,
        requestedAt: _requestedAt,
      } = match.groups;
      const nonce = _nonce;
      const requestedAt = DateTime.fromISO(_requestedAt);

      return {
        appName,
        domain,
        address,
        nonce,
        requestedAt,
      };
    };

    export const addressVerification = (): string => {
      return `I am signing to verify with Luma my ownership of the address. (time: ${DateTime.now().toISO()})`;
    };

    const TIME_ADDRESS_VERIFICATION_REGEX =
      /I am signing to verify with Luma my ownership of the address\. \(time: ([\d\-\w\:\+\.]+)\)/;
    export const parseAddressVerification = ({
      message,
    }: {
      message: string;
    }): DateTime | null => {
      const matches = TIME_ADDRESS_VERIFICATION_REGEX.exec(message);
      const dateTimeIso = matches?.[1];
      if (dateTimeIso) {
        const dateTime = DateTime.fromISO(dateTimeIso);
        if (dateTime.isValid) {
          return dateTime;
        }
      }
      return null;
    };

    export const tweet = ({ wallet }: { wallet: Solana.Address }) => {
      // TODO: update this to promote Glow ID
      return `Testing: ${wallet}`;
    };

    export const tweetVerify = ({
      tweet,
      wallet,
    }: {
      tweet: string;
      wallet: Solana.Address;
    }): boolean => {
      const regex = new RegExp(`\\b${wallet}\\b`);
      return regex.test(tweet);
    };

    export const deviceTokenVerification = ({ token }: { token: string }) =>
      token;
  }

  export enum Network {
    Mainnet = "mainnet",
    Devnet = "devnet",
    Localnet = "localnet",
  }
  export const NetworkZ = z.nativeEnum(Network);
  export const Networks = Object.values(Network);

  export type BasicAccountInfo = {
    sol: SolAmount;
    address: Solana.Address;
  } & {
    type: "program" | "account" | "token";
  };

  export enum TransactionError {
    // Unknown error.
    Generic = "generic",
    // We think you may be trying to send a transaction on the wrong network
    WrongNetwork = "wrong-network",
    // Insufficient SOL or token balance to perform this transaction.
    InsufficientFunds = "insufficient_funds",
    // Insufficient funds to pay for transaction fee.
    InsufficientFundsForFee = "insufficient_funds_fee",
    // Provided fee doesn't match the program's constraints.
    InvalidFee = "invalid_fee",
    // The token mint is invalid.
    InvalidMint = "invalid_mint",
    // The number of provided or required signer is invalid.
    InvalidSignerCount = "invalid_signer_count",
    // Invalid amount, overflow or underflow.
    InvalidValue = "invalid_value",
    // Account already in use and can't be created.
    AccountAlreadyInUse = "account_in_use",
    // Swapping a token with itself.
    RepeatedMintForSwap = "repeated_mint_swap",
    // Amount result in 0 tokens being swapped.
    ZeroTradingTokensForSwap = "zero_tokens_swap",
    // Exceeds desired slippage.
    ExceedsSlippage = "exceeds_slippage",
    // The transaction is malformed. This is likely a bug in the program.
    Malformed = "malformed",
    // The transaction has already been processed.
    AlreadyProcessed = "already_processed",
    // The transaction is no longer valid and requires a refresh.
    RefreshRequired = "refresh_required",
  }

  export const abbreviateAddress = (address: string): TString => {
    if (address.length <= 10) {
      return address as TString;
    }
    return `${address.slice(0, 5)}…${address.slice(-5)}` as TString;
  };

  export enum SpecialNftCollection {
    /**
     * This collection will have all NFTs that someone owns that are not
     * part of a collection *or* are the only NFT in a collection that
     * someone owns.
     */
    Singles = "singles-collection",
    GlowIdGenesis = "glow-id-genesis-collection",
  }

  export const SpecialNftCollectionToInfo: Record<
    SpecialNftCollection,
    { name: string }
  > = {
    [SpecialNftCollection.Singles]: { name: "Others" },
    [SpecialNftCollection.GlowIdGenesis]: { name: "Genesis NFTs" },
  };

  export const SpecialNftCollectionIds = Object.values(SpecialNftCollection);

  export enum Marketplace {
    MagicEden = "magic_eden",
    Solanart = "solanart",
    OpenSea = "opensea",
    Fractal = "fractal",
    FormFunction = "formfn",
    Hyperspace = "hyperspace",
    Yawww = "yawww",
  }
  export type MarketplaceInfo = {
    display: TString;
    escrow?: boolean;
    fee_percent?: number;
    custom_fees?: boolean;

    allow_list?: boolean;
    allow_unlist?: boolean;
    allow_withdraw?: boolean;
    allow_deposit?: boolean;
    allow_manage_bids?: boolean;
  };
  export const Marketplaces = [
    Marketplace.FormFunction,
    Marketplace.Fractal,
    Marketplace.Hyperspace,
    Marketplace.MagicEden,
    Marketplace.OpenSea,
    Marketplace.Solanart,
    Marketplace.Yawww,
  ];
  export const MarketplaceToInfo: Record<Marketplace, MarketplaceInfo> = {
    [Marketplace.MagicEden]: {
      display: "Magic Eden" as TString,
      escrow: true,
      fee_percent: 2,

      allow_list: true,
      allow_unlist: true,
      allow_withdraw: true,
      allow_deposit: true,
      allow_manage_bids: true,
    },
    [Marketplace.Hyperspace]: {
      display: "Hyperspace" as TString,
      escrow: false,
      fee_percent: 1,

      allow_list: true,
      allow_unlist: true,
    },
    [Marketplace.Solanart]: {
      display: "Solanart" as TString,
      escrow: true,
      fee_percent: 0,
      custom_fees: true,

      allow_list: true,
      allow_unlist: true,
    },
    [Marketplace.OpenSea]: {
      display: "OpenSea" as TString,
    },
    [Marketplace.FormFunction]: {
      display: "Formfunction" as TString,
    },
    [Marketplace.Fractal]: {
      display: "Fractal" as TString,
    },
    [Marketplace.Yawww]: {
      display: "Yawww" as TString,
    },
  };
  export const MarketplaceZ = z.enum([
    Marketplace.MagicEden,
    Marketplace.Solanart,
    Marketplace.OpenSea,
    Marketplace.FormFunction,
    Marketplace.Fractal,
    Marketplace.Hyperspace,
    Marketplace.Yawww,
  ]);
}

/**
 * Verifies that a signature for a given message is by a given signer.
 *
 * In public / private key cryptographer, the signer has two keys - a public and private key - that
 * match. When the signer signs a message, they sign the message with their secret key.
 *
 * To confirm that the message is signed correctly, anyone can compare the signature, the message,
 * and the signer's public key to confirm that they are valid together.
 *
 * @param signature
 * @param message
 * @param signer
 */
export const verifySignature = ({
  signature,
  message,
  signer,
}: {
  signature: Base64;
  message: string;
  signer: Solana.Address;
}) => {
  const messageUint = new Uint8Array(Buffer.from(message));
  const signatureUint = new Uint8Array(Buffer.from(signature, "base64"));
  const addressUint = bs58.decode(signer);

  if (!sign.detached.verify(messageUint, signatureUint, addressUint)) {
    console.error("Problem verifying signature...");
    throw new Error("The Solana signature is invalid.");
  }
};
