import { useUserContext } from "@components/auth/UserContext";
import { GlowButton } from "../atoms/GlowButton";

const abbreviateAddress = (address: string): string => {
  if (address.length <= 10) {
    return address;
  }
  return `${address.slice(0, 5)}…${address.slice(-5)}`;
};

export const GlowConnectButton = ({ className }: { className?: string }) => {
  const { user, signIn } = useUserContext();

  return (
    <GlowButton
      className={className}
      color="brand"
      label={
        user?.glowId?.handle
          ? `${user.glowId.handle}.glow`
          : user?.address
          ? abbreviateAddress(user.address)
          : "Connect Glow"
      }
      size="small"
      onClick={async () => {
        await signIn();
      }}
      rounded
    />
  );
};
