import classNames from "classnames";
import React from "react";

export const Spinner = ({ className }: { className?: string }) => {
  return (
    <svg className={classNames("lux-spinner", className)} viewBox="0 0 66 66">
      <circle
        className="path"
        fill="none"
        stroke="currentColor"
        strokeWidth="8"
        strokeLinecap="round"
        cx="33"
        cy="33"
        r="28"
      />
    </svg>
  );
};
