import classNames from "classnames";
import React, { DOMAttributes, useRef } from "react";

import { useBoolean } from "../hooks/useBoolean";
import { useMediaQuery } from "../hooks/useMediaQuery";
import { LuxLink } from "./LuxLink";
import { OptionalTooltip } from "./OptionalTooltip";
import { Spinner } from "./Spinner";

export type LuxButtonProps = {
  label: string | React.ReactNode;
  icon?: React.ReactNode;
  fullWidth?: boolean;
  size?: "small" | "medium" | "large";
  iconPlacement?: "left" | "right" | "icon-only" | "icon-only-compact";
  color?:
    | "brand"
    | "discord"
    | "error"
    | "ethereum"
    | "glow"
    | "google"
    | "light"
    | "primary"
    | "secondary"
    | "slack"
    | "solana"
    | "success"
    | "twitter"
    | "youtube"
    | "zoom"
    | "microsoft";
  variant?: "solid" | "outline" | "link" | "naked";
  rounded?: boolean;
  disabled?: boolean;
  loading?: boolean;
  onClick?: DOMAttributes<HTMLElement>["onClick"];
  href?: string;
  query?: React.ComponentPropsWithoutRef<typeof LuxLink>["query"];
  openInNewTab?: boolean;
  forcePageChange?: boolean;
  className?: string;
  hideTooltipForIconOnly?: boolean;
  shouldSubmit?: boolean;
} & (React.AnchorHTMLAttributes<HTMLAnchorElement> &
  React.ButtonHTMLAttributes<HTMLButtonElement>);

export const LuxButton = React.forwardRef(
  (
    {
      label,
      icon,
      fullWidth,
      size = "medium",
      iconPlacement = "left",
      color = "primary",
      variant = "solid",
      rounded,
      disabled,
      loading,
      onClick,
      href,
      query,
      openInNewTab,
      forcePageChange,
      hideTooltipForIconOnly,
      className,
      shouldSubmit,
      ...props
    }: LuxButtonProps,
    ref
  ) => {
    const Button = href ? LuxLink : "button";
    // button doesn't support openInNewTab so we don't pass it down
    const extraProps = href ? { openInNewTab, forcePageChange } : {};
    const internalLoading = useBoolean();
    const svg = internalLoading.value || loading ? <Spinner /> : icon;
    const tooltipRef = useRef<{ hide: () => unknown }>(null);

    const supportsHover = useMediaQuery("(hover: hover)");
    const iconOnly =
      iconPlacement === "icon-only" || iconPlacement === "icon-only-compact";
    const wrapInTooltip = iconOnly && !hideTooltipForIconOnly && supportsHover;

    return (
      <OptionalTooltip
        ref={tooltipRef}
        tooltipLabel={wrapInTooltip && label ? label : null}
      >
        <Button
          {...props}
          ref={ref as any}
          href={href!}
          query={query}
          aria-label={iconOnly && typeof label === "string" ? label : undefined}
          className={classNames(
            "btn luma-button flex-center",
            size,
            color,
            variant,
            className,
            {
              loading: internalLoading.value || loading,
              round: rounded,
              "full-width": fullWidth,
              "no-icon": !icon,
              "icon-left": icon && iconPlacement === "left",
              "icon-right": icon && iconPlacement === "right",
              "icon-only": icon && iconPlacement === "icon-only",
              "icon-only-compact":
                icon && iconPlacement === "icon-only-compact",
            }
          )}
          onClick={async (e) => {
            if (!onClick) {
              return;
            }

            internalLoading.setTrue();
            try {
              await onClick(e);
            } finally {
              tooltipRef.current?.hide();
              internalLoading.setFalse();
            }
          }}
          disabled={internalLoading.value || loading || disabled}
          type={href ? undefined : shouldSubmit ? "submit" : "button"}
          {...extraProps}
        >
          {iconPlacement === "left" && svg}
          {iconOnly ? svg : <div className="label">{label}</div>}
          {iconPlacement === "right" && svg}
        </Button>
      </OptionalTooltip>
    );
  }
);

