import { sample } from "./array-utils";
import { HexColor } from "./data-types";

export enum ColorTheme {
  // Solid Colors
  SolidPink = "solid_pink",
  SolidOrange = "solid_orange",
  SolidYellow = "solid_yellow",
  SolidGreen = "solid_green",
  SolidBlue = "solid_blue",
  SolidPurple = "solid_purple",

  SolidGray = "solid_gray",
}

export enum FontTheme {
  Europa = "europa",
  Futura = "futura",
  Gopher = "gopher",
  Jubilat = "jubilat",
  MrEaves = "mr_eaves",
  Paralucent = "paralucent",
  ProximaNova = "proxima_nova",
  RocGrotesk = "roc_grotesk",
  System = "system",
}

export enum LumaColor {
  Black = "black",
  Gray = "gray",
  Cranberry = "cranberry",
  Barney = "barney",
  Red = "red",
  Green = "green",
  Blue = "blue",
  Purple = "purple",
  Yellow = "yellow",
  Orange = "orange",
}

export namespace Color {
  export const randomColor = (): LumaColorWithoutGray =>
    sample([
      LumaColor.Cranberry,
      LumaColor.Barney,
      LumaColor.Red,
      LumaColor.Green,
      LumaColor.Blue,
      LumaColor.Purple,
      LumaColor.Yellow,
      LumaColor.Orange,
    ]);
}

export type LumaColorWithoutGray = Exclude<
  LumaColor,
  LumaColor.Gray | LumaColor.Black
>;

export namespace Colors {
  export const DIVIDER_COLOR = "#ebeced";

  export const PRIMARY_COLOR = "#131517";
  export const SECONDARY_COLOR = "#737577";
  export const TERTIARY_COLOR = "#b3b5b7";

  export const PRIMARY_BG_COLOR = "#ffffff";
  export const SECONDARY_BG_COLOR = "#f4f5f6";

  export const DARK_PRIMARY_BG_COLOR = "#131517";
  export const DARK_SECONDARY_BG_COLOR = "#212325";

  export const CALENDAR_RED = "#d86156";

  export const BORDER_COLOR = "#ebeced";

  export const CTA_COLOR: Record<LumaColorWithoutGray, HexColor> = {
    [LumaColor.Cranberry]: "#de3163" as HexColor,
    [LumaColor.Barney]: "#bb2dc7" as HexColor,
    [LumaColor.Purple]: "#682fff" as HexColor,
    [LumaColor.Blue]: "#325ffa" as HexColor,
    [LumaColor.Green]: "#07a460" as HexColor,
    [LumaColor.Yellow]: "#d19d20" as HexColor,
    [LumaColor.Orange]: "#ec660d" as HexColor,
    [LumaColor.Red]: "#e83b47" as HexColor,
  };

  export const COLORS_WITHOUT_GRAY: LumaColorWithoutGray[] = [
    LumaColor.Cranberry,
    LumaColor.Barney,
    LumaColor.Purple,
    LumaColor.Blue,
    LumaColor.Green,
    LumaColor.Yellow,
    LumaColor.Orange,
    LumaColor.Red,
  ];

  export const COLORS = [LumaColor.Gray, ...COLORS_WITHOUT_GRAY] as const;
}

export const LumaColorToInfo: Record<
  LumaColor,
  { name: string; ctaColor: string }
> = {
  [LumaColor.Gray]: { name: "Gray", ctaColor: Colors.PRIMARY_COLOR },
  [LumaColor.Black]: { name: "Black", ctaColor: Colors.PRIMARY_BG_COLOR },
  [LumaColor.Cranberry]: {
    name: "Cranberry",
    ctaColor: Colors.CTA_COLOR[LumaColor.Cranberry],
  },
  [LumaColor.Barney]: {
    name: "Barney",
    ctaColor: Colors.CTA_COLOR[LumaColor.Barney],
  },
  [LumaColor.Red]: { name: "Red", ctaColor: Colors.CTA_COLOR[LumaColor.Red] },
  [LumaColor.Green]: {
    name: "Green",
    ctaColor: Colors.CTA_COLOR[LumaColor.Green],
  },
  [LumaColor.Blue]: {
    name: "Blue",
    ctaColor: Colors.CTA_COLOR[LumaColor.Blue],
  },
  [LumaColor.Purple]: {
    name: "Purple",
    ctaColor: Colors.CTA_COLOR[LumaColor.Purple],
  },
  [LumaColor.Yellow]: {
    name: "Yellow",
    ctaColor: Colors.CTA_COLOR[LumaColor.Yellow],
  },
  [LumaColor.Orange]: {
    name: "Orange",
    ctaColor: Colors.CTA_COLOR[LumaColor.Orange],
  },
};
