import { CurrentCountryContext } from "@components/all-pages/CurrentCountryContext";
import { NetworkProvider } from "@components/all-pages/NetworkProvider";
import { UserProvider } from "@components/auth/UserContext";
import { ErrorBoundary } from "@components/ErrorBoundary";
import { GlowError } from "@components/GlowError";
import { SearchProvider } from "@components/search/SearchProvider";
import { FALLBACK_COUNTRY_CODE, GLOW_HOST } from "@luma-team/shared";
import { ComponentsOverridesContext } from "@lux/atoms/components/ComponentsOverridesContext";
import { addGlowIdDict } from "@glow/react/components/glow-id/GlowIdProvider";
import { useIsMounted } from "@lux/atoms/hooks/useIsMounted";
import "@lux/sass/styles/app.scss";
import App, { AppContext } from "next/app";
import Head from "next/head";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useRef } from "react";
import ReactDOM from "react-dom";
import { Toaster } from "react-hot-toast";
import "../styles/overrides.scss";

// Exclude these routes from showing the Apple app store banner
const APPSTORE_EXCLUDE = ["/safari"];

const COMPONENTS_OVERRIDES: React.ContextType<
  typeof ComponentsOverridesContext
> = {
  Link: React.forwardRef(
    ({ href, ...props }, ref: React.ForwardedRef<HTMLAnchorElement>) => (
      <Link href={href} {...props} ref={ref}></Link>
    )
  ),
  HOST: GLOW_HOST,
};

export default function GlowApp(appProps: {
  Component: React.Factory<any>;
  pageProps: any;
  err: Error;
  countryCode: string;
}) {
  const { Component, pageProps, err } = appProps;
  const isMounted = useIsMounted();
  const router = useRouter();
  const showAppStore = !APPSTORE_EXCLUDE.includes(router.pathname);

  // Workaround for https://github.com/zeit/next.js/issues/8592
  const modifiedPageProps = { ...pageProps, err };

  // Adding Glow ID dict must happen while rendering,
  // otherwise we run into SSR hydration issues.
  const hasHandledGlowIdDict = useRef(false);
  if (!hasHandledGlowIdDict.current) {
    if ("glow_id_dict" in pageProps) {
      addGlowIdDict(pageProps);
    } else if ("initialData" in pageProps) {
      addGlowIdDict(pageProps.initialData);
    }
    hasHandledGlowIdDict.current = true;
  }

  return (
    <ErrorBoundary>
      <ComponentsOverridesContext.Provider value={COMPONENTS_OVERRIDES}>
        <NetworkProvider>
          <UserProvider>
            <CurrentCountryContext.Provider
              value={{ country_code: appProps.countryCode }}
            >
              <SearchProvider>
                {pageProps?.errorCode ? (
                  <GlowError statusCode={pageProps.errorCode} />
                ) : (
                  <>
                    <Head>
                      <title>
                        Glow Wallet — Your New Favorite Solana Wallet
                      </title>
                      {showAppStore && (
                        <meta
                          name="apple-itunes-app"
                          content="app-id=1599584512"
                        />
                      )}
                      <link
                        rel="apple-touch-icon"
                        href="/apple-touch-icon.png"
                      />
                      <link
                        key="favicon"
                        rel="shortcut icon"
                        href="/favicon.png"
                      />
                    </Head>

                    {isMounted &&
                      ReactDOM.createPortal(
                        <Toaster position="bottom-center" />,
                        document.body
                      )}

                    <Component {...modifiedPageProps} />
                  </>
                )}
              </SearchProvider>
            </CurrentCountryContext.Provider>
          </UserProvider>
        </NetworkProvider>
      </ComponentsOverridesContext.Provider>
    </ErrorBoundary>
  );
}

GlowApp.getInitialProps = async (appContext: AppContext) => {
  const { ctx } = appContext;
  let countryCode = FALLBACK_COUNTRY_CODE;

  if (ctx.req) {
    const cloudflareIpCountry =
      // Cloudflare provides country in production
      (ctx.req.headers["cf-ipcountry"] as string) ||
      // Vercel provides country in staging (luma-dev.com)
      (ctx.req.headers["x-vercel-ip-country"] as string);

    // Cloudflare uses:
    // - XX for clients without country code data
    // - T1 for clients using the Tor network
    const invalidCountryCodes = ["XX", "T1"];
    if (
      cloudflareIpCountry &&
      !invalidCountryCodes.includes(cloudflareIpCountry)
    ) {
      countryCode = cloudflareIpCountry;
    }
  }

  const extraProps = await App.getInitialProps(appContext);

  return { countryCode, ...extraProps };
};
