import { SocialHead } from "@components/all-pages/SocialHead";
import { SolanaPage } from "@components/nav/SolanaPage";
import { GlowButton } from "./atoms/GlowButton";

export const GlowError = ({ statusCode }: { statusCode: number }) => {
  if (statusCode >= 400 && statusCode < 500) {
    statusCode = 404;
  }

  let title = "Error";
  if (statusCode >= 500) {
    title = "Server Error";
  } else if (statusCode == 404) {
    title = "Page Not Found";
  }

  let description =
    "Looks like you discovered a page that doesn't exist or you don't have access to.";
  if (statusCode >= 500) {
    description =
      "Oops! Something went wrong on our side. We are sorry about that - please retry in a bit.";
  }

  return (
    <SolanaPage title={title} footer={false}>
      <div className="error grid-background flex-center-center p-4">
        <SocialHead subtitle={title} />

        <div className="flex-center flex-column">
          <img
            src={`/error/${statusCode == 404 ? 404 : 500}.png`}
            alt={title}
          />
          <h1>{title}</h1>
          <p className="fs-lg text-tertiary">{description}</p>
          <div className="mt-3">
            <GlowButton label="Return Home" size="large" href="/" rounded />
          </div>
        </div>
      </div>

      <style jsx>{`
        .error {
          position: fixed;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          text-align: center;

          :global(.simple-top-nav) {
            position: fixed;
            top: 0;
            left: 0;
          }

          img {
            width: 96px;
            filter: drop-shadow(var(--shadow-xs));
          }

          h1 {
            margin: 2rem 0 1.5rem 0;
          }

          p {
            max-width: 450px;
          }
        }
      `}</style>
    </SolanaPage>
  );
};
