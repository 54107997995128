import * as Sentry from "@sentry/react";
import React from "react";
import { GlowError } from "./GlowError";

export const ErrorBoundary = ({
  children,
  fallback,
}: {
  children: React.ReactNode;
  fallback?: React.ReactNode;
}) => (
  <Sentry.ErrorBoundary
    fallback={fallback ? <>{fallback}</> : <GlowError statusCode={500} />}
  >
    {children}
  </Sentry.ErrorBoundary>
);
