// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const isNetworkError = (error: any): boolean => {
  if (!error) {
    return false;
  }

  return (
    error.code === "ECONNABORTED" ||
    error.message?.toLowerCase().includes("network error") ||
    false
  );
};
