import { z } from "zod";
import { UserSocialLink } from "../schema/UserSchema";
import { URL_REGEX } from "./url";

export const startsWithHttp = (url: string): boolean => {
  return (
    url.toLowerCase().startsWith("http://") ||
    url.toLowerCase().startsWith("https://")
  );
};

const isValidUrl = (url: string): boolean => {
  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
};

export const isAbsoluteUrl = (url: string) => {
  if (typeof url !== "string") {
    throw new TypeError(`Expected a \`string\`, got \`${typeof url}\``);
  }

  const isUrl = URL_REGEX.test(url);
  if (!isUrl) {
    return false;
  }

  return startsWithHttp(url);
};

export const normalizeUrl = (url: string): string | null => {
  const isUrl = URL_REGEX.test(url);

  if (!isUrl) {
    return null;
  }

  if (isAbsoluteUrl(url)) {
    return url;
  }

  return "http://" + url;
};

export enum SocialPlatform {
  Facebook = "facebook",
  Instagram = "instagram",
  LinkedIn = "linkedin",
  Pinterest = "pinterest",
  Discord = "discord",
  Substack = "substack",
  Spotify = "spotify",
  TikTok = "tiktok",
  Twitch = "twitch",
  Twitter = "twitter",
  Youtube = "youtube",
  Website = "website",
}

export const SocialPlatformZ = z.nativeEnum(SocialPlatform);

const USERNAME_PATTERN = "[a-zA-Z\\-._0-9]{1,30}";
const USERNAME_REGEX = new RegExp(`^${USERNAME_PATTERN}$`);

const LI_USERNAME_REGEX = new RegExp(
  ["linkedin\\.com/in/(", USERNAME_PATTERN, ")"].join("")
);
const PINTEREST_USERNAME_REGEX = new RegExp(
  ["pinterest\\.com/(", USERNAME_PATTERN, ")"].join("")
);
const INSTAGRAM_USERNAME_REGEX = new RegExp(
  ["instagram\\.com/(", USERNAME_PATTERN, ")"].join("")
);
const SUBSTACK_USERNAME_REGEX = new RegExp(
  ["([A-Za-z0-9-]+).substack\\.com"].join("")
);
const TIKTOK_USERNAME_REGEX = new RegExp(
  ["tiktok\\.com/@(", USERNAME_PATTERN, ")"].join("")
);
const TWITCH_USERNAME_REGEX = new RegExp(
  ["twitch\\.com/(", USERNAME_PATTERN, ")"].join("")
);
const TWITTER_USERNAME_REGEX = new RegExp(
  ["twitter\\.com/(", USERNAME_PATTERN, ")"].join("")
);

const PLATFORM_TO_REGEX: Partial<Record<SocialPlatform, RegExp>> = {
  [SocialPlatform.Instagram]: INSTAGRAM_USERNAME_REGEX,
  [SocialPlatform.LinkedIn]: LI_USERNAME_REGEX,
  [SocialPlatform.Pinterest]: PINTEREST_USERNAME_REGEX,
  [SocialPlatform.Substack]: SUBSTACK_USERNAME_REGEX,
  [SocialPlatform.TikTok]: TIKTOK_USERNAME_REGEX,
  [SocialPlatform.Twitch]: TWITCH_USERNAME_REGEX,
  [SocialPlatform.Twitter]: TWITTER_USERNAME_REGEX,
};

export const getSocialLinkHref = ({
  platform,
  usernameOrUrl,
}: {
  platform: SocialPlatform;
  usernameOrUrl: string | null;
}): string | null => {
  if (!usernameOrUrl) {
    return null;
  }

  usernameOrUrl = usernameOrUrl.trim();
  if (!usernameOrUrl) {
    return null;
  }

  const isUrl = isValidUrl(usernameOrUrl);
  if (isUrl) {
    return usernameOrUrl;
  }

  switch (platform) {
    case SocialPlatform.Facebook: {
      return `https://facebook.com/${usernameOrUrl}`;
    }
    case SocialPlatform.Instagram: {
      return `https://instagram.com/${usernameOrUrl}`;
    }
    case SocialPlatform.LinkedIn: {
      return `https://linkedin.com/in/${usernameOrUrl}`;
    }
    case SocialPlatform.Pinterest: {
      return `https://pinterest.com/${usernameOrUrl}`;
    }
    case SocialPlatform.Substack: {
      return `https://${usernameOrUrl}.substack.com`;
    }
    case SocialPlatform.TikTok: {
      return `https://tiktok.com/@${usernameOrUrl}`;
    }
    case SocialPlatform.Twitch: {
      return `https://twitch.tv/${usernameOrUrl}`;
    }
    case SocialPlatform.Twitter: {
      return `https://twitter.com/${usernameOrUrl}`;
    }
    case SocialPlatform.Youtube: {
      return `https://youtube.com/channel/${usernameOrUrl}`;
    }
    default: {
      return null;
    }
  }
};

/**
 * Validate the user input based on the social platform
 *
 * Output
 * - valid — is this a valid link that will be shown on the social preview
 * - url - what is the URL that we will show / click on
 * - value_to_save - what is the value we should store to the DB / display in the input (often the input)
 */
export const validateSocialLink = ({
  platform,
  value,
}: {
  platform: SocialPlatform;
  value: string;
}): {
  valid: boolean;
  url: string | null;
  value_to_save: string;
} => {
  if (!value) {
    return { valid: false, url: null, value_to_save: "" };
  }

  value = value.trim();

  const isUsername = USERNAME_REGEX.test(value);
  if (isUsername) {
    const url = getSocialLinkHref({ platform, usernameOrUrl: value });
    if (url) {
      return { valid: true, url, value_to_save: value };
    }
  }

  const url = normalizeUrl(value);
  if (url) {
    const regex = PLATFORM_TO_REGEX[platform];
    if (regex) {
      const match = regex.exec(value);
      if (match) {
        const username = match[1];
        return { valid: true, url, value_to_save: username };
      }
    }

    return { valid: true, url, value_to_save: url };
  }

  return { valid: false, url: null, value_to_save: value };
};

export const getSocialLinkForPlatform = ({
  social_links,
  platform,
}: {
  social_links: UserSocialLink[];
  platform: SocialPlatform;
}): string | null => {
  const match = social_links.find((link) => link.platform === platform);
  if (match) {
    return match.value;
  }

  return null;
};

export const SocialPlatformToDisplay: Record<SocialPlatform, string> = {
  [SocialPlatform.Discord]: "Discord",
  [SocialPlatform.Facebook]: "Facebook",
  [SocialPlatform.Instagram]: "Instagram",
  [SocialPlatform.LinkedIn]: "LinkedIn",
  [SocialPlatform.Pinterest]: "Pinterest",
  [SocialPlatform.Spotify]: "Spotify",
  [SocialPlatform.Substack]: "Substack",
  [SocialPlatform.TikTok]: "TikTok",
  [SocialPlatform.Twitch]: "Twitch",
  [SocialPlatform.Twitter]: "Twitter",
  [SocialPlatform.Website]: "Website",
  [SocialPlatform.Youtube]: "Youtube",
};
