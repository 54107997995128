export const isUnsplashImage = (url: string): boolean => {
  if (!url) {
    return false;
  }
  return url.startsWith("https://images.unsplash.com/");
};

export const croppedUnsplashImage = ({
  src,
  width,
  height,
  dpr = 1,
}: {
  src: string;
  width: number;
  height?: number;
  dpr?: number;
}): string => {
  try {
    const widthToRequest = width * dpr;
    const heightToRequest = height
      ? height * dpr
      : Math.floor(widthToRequest / 2);

    const url = new URL(src);
    url.searchParams.set("fit", "crop");
    url.searchParams.set("w", widthToRequest.toString());
    url.searchParams.set("h", heightToRequest.toString());
    return url.toString();
  } catch (error: any) {
    console.error(error);
    return src;
  }
};
