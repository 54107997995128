import toast from "react-hot-toast";

export const DEFAULT_TOAST_STYLE = {
  style: {
    minWidth: "200px",
    color: "var(--toast-default-color)",
    backgroundColor: "var(--primary-color)",
  },
  iconTheme: {
    primary: "var(--toast-default-color)",
    secondary: "var(--gray)",
  },
};

export const SUCCESS_TOAST_STYLE = {
  style: {
    minWidth: "200px",
    color: "white",
    backgroundColor: "var(--success-color)",
  },
  iconTheme: {
    primary: "white",
    secondary: "var(--success-color)",
  },
};

export const ERROR_TOAST_STYLE = {
  style: {
    minWidth: "200px",
    color: "white",
    backgroundColor: "var(--error-color)",
  },
  iconTheme: {
    primary: "white",
    secondary: "var(--error-color)",
  },
};

export const toastSuccess = (message: string, id?: string | null) => {
  toast.success(message, { id: id ?? undefined, ...SUCCESS_TOAST_STYLE });
};

export const toastError = (message: string, id?: string | null) => {
  toast.error(message, { id: id ?? undefined, ...ERROR_TOAST_STYLE });
};

export const toastLoading = (
  message: string | JSX.Element,
  id?: string | null
): string => {
  return toast.loading(message, {
    id: id ?? undefined,
    ...DEFAULT_TOAST_STYLE,
  });
};
