import { Currency } from "..";
import { DbCalendar } from "./CalendarSchema";
import { DbUser } from "./UserSchema";
import { BaseTable } from "./schema-utils";

export enum DbTable {
  AiImage = "ai_image",
  AnalyticsPageView = "analytics_page_view",
  AnalyticsProfileClick = "analytics_profile_click",
  ApiKey = "api_key",
  AudienceTag = "member_tag",
  AudienceTagApplied = "member_tag_applied",
  Calendar = "calendar",
  CalendarAdmin = "calendar_admin",
  CalendarEvent = "calendar_event",
  CalendarMember = "calendar_member",
  CalendarPost = "calendar_post",
  CdnFile = "cdn_file",
  Charge = "charge",
  ChatConversation = "chat_conversation",
  ChatMessage = "chat_message",
  ChatParticipant = "chat_participant",
  Collection = "collection",
  Community = "community",
  CommunityMember = "community_member",
  Coupon = "coupon",
  DappInfo = "dapp_info",
  DeviceToken = "device_token",
  DiscoverEvent = "discover_event",
  DiscoverPlace = "discover_place",
  DiscoverPost = "discover_post",
  DiscoverSubscriber = "discover_subscriber",
  EmailDraft = "email_draft",
  EmailIssue = "email_issue",
  EmailMessage = "email_message",
  Event = "event",
  EventEmail = "event_email",
  EventHost = "event_host",
  EventPost = "event_post",
  EventTicketType = "event_ticket_type",
  ExchangeRate = "exchange_rate",
  GeneratedImage = "generated_image",
  GlowAnalyticsEvent = "glow_analytics_event",
  GlowDeviceToken = "glow_device_token",
  GlowIdAccount = "glow_id_account",
  GlowIdGenesisNftInfo = "glow_id_genesis_nft_info",
  GlowIdInvite = "glow_id_invite",
  GlowIdMetadata = "glow_id_metadata",
  GlowIdWhitelist = "glow_id_whitelist",
  GlowSignedMessage = "glow_signed_message",
  GlowSync = "glow_sync",
  GlowTransactionNotification = "glow_transaction_notification",
  GlowUser = "glow_user",
  HelpArticle = "help_article",
  IcsAuthorization = "ics_authorization",
  JobPopulateNftCollection = "job_populate_nft_collection",
  LumaAnalyticsEvent = "luma_analytics_event",
  LumaPlusSubscription = "luma_plus_subscription",
  LumaPointAdjustment = "luma_point_adjustment",
  LumaPointUsed = "luma_point_used",
  Member = "member",
  NftokenCollection = "nftoken_collection",
  NftokenMetadata = "nftoken_metadata",
  NftokenNft = "nftoken_nft",
  Notification = "notification",
  PageReport = "page_report",
  PhoneNumberBlacklist = "phone_number_blacklist",
  PostmarkEmail = "postmark_email",
  ProfileBlock = "profile_block",
  ProxyClick = "proxy_click",
  Rsvp = "rsvp",
  RsvpPasskitToken = "rsvp_passkit_token",
  SolanaBlock = "solana_block",
  SolanaCoinMetadata = "solana_coin_metadata",
  SolanaNftCollection = "solana_nft_collection",
  SolanaNftMetadata = "solana_nft_metadata",
  SolanaNftTransaction = "solana_nft_transaction",
  SolanaSimulatedTransaction = "solana_simulated_transaction",
  SolanaStakingReward = "solana_staking_reward",
  SolanaSwapRoute = "solana_swap_route",
  SolanaToken = "solana_token",
  SolanaValidator = "solana_validator",
  StripeAccount = "stripe_account",
  StripeInvoice = "stripe_invoice",
  StripeMetadata = "stripe_metadata",
  StripeOnrampSession = "stripe_onramp_session",
  SurveyResponse = "survey_response",
  Tag = "tag",
  TagApplied = "tag_applied",
  Team = "team",
  TeamMember = "team_member",
  Tip = "tip",
  TwilioText = "twilio_text",
  TwoFactorAuthMethod = "two_factor_auth_method",
  UploadedFile = "uploaded_file",
  Url = "url",
  User = "user",
  UserActivity = "user_activity",
  UserMeta = "user_meta",
  VerificationRequest = "verification_request",
  Video = "video",
  ZoomWebhookUpdate = "zoom_webhook_update",
}

export enum GlowAndroidSecretsTable {
  GlowAndroidEncryptionKey = "glow_android_encryption_key",
}

// This is a separate DB on Aurora / Google Cloud
export enum SolanaDbTable {
  SolanaTransaction = "solana_transaction",
  SolanaTransactionAccount = "solana_transaction_account",
}

export enum ScrapedDbTable {
  ScrapedEvent = "scraped_event",
  ScrapedHost = "scraped_host",
  ScrapedHostEmail = "scraped_host_email",
  WebsiteToEmails = "website_to_emails",
}

export type DbUrl = {
  url: string;
  kind: UrlKind;
};

export enum UrlKind {
  Event = "event",
  Calendar = "calendar",
  User = "user",
}

export type DbTip = {
  api_id: string;

  tipper_email: string;
  tipper_api_id: string;
  event_api_id: string | null;
  recipient_api_id: string;

  member_api_id: string;

  stripe_amount_cents: number;
  stripe_payment_intent_id: string;
  stripe_account_id: string | null;
  stripe_currency: Currency | null;

  stripe_connect_customer_id: string | null;
  stripe_platform_customer_id: string | null;
  stripe_charge_id: string | null;

  usd_cents: number | null;

  message: string | null;
  block_info: {
    title: string | null;
    description: string | null;
    block_api_id: string;
  } | null;

  created_at: string;
  updated_at: string;
};

export type DbApiKey = {
  api_key: string;

  user_api_id: DbUser["api_id"];
  calendar_api_id: DbCalendar["api_id"] | null;
} & BaseTable<"api_key">;
