import { SearchBody } from "@components/search/SearchBody";
import { SearchContext } from "@components/search/search-types";
import { SearchOverlay } from "@components/search/SearchOverlay";
import { useBoolean } from "@lux/atoms/hooks/useBoolean";
import React, { useEffect, useState } from "react";

export const SearchProvider = ({ children }: { children: React.ReactNode }) => {
  const searchShown = useBoolean();
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleEscape = (event: KeyboardEvent) => {
    if (event.key === "k" && event.metaKey) {
      searchShown.setTrue();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleEscape);
    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SearchContext.Provider
      value={{
        showSearch: searchShown.setTrue,
        hideSearch: searchShown.setFalse,
        currentIndex,
        setCurrentIndex,
      }}
    >
      {children}

      <SearchOverlay onHide={searchShown.setFalse}>
        {searchShown.value && <SearchBody />}
      </SearchOverlay>
    </SearchContext.Provider>
  );
};
