import * as Sentry from "@sentry/browser";
import toast from "react-hot-toast";

import { isNetworkError as isErrorNetworkError } from "./error";

export type ReportErrorOptions = {
  error?: any;
  payload?: { [key: string]: any };
  skipSentry?: boolean;
  toast?: { id?: string; message: string; opts?: any };
  errorId?: string;
};

/**
 * Suggested usage:
 * try {
 *   ...
 * } catch (error) {
 *   reportError(message, {error})
 * }
 *
 * In that scenario, we will take sane defaults and show the right thing to the user.
 *
 * The decisions that this function makes are:
 * - Do we show a toast? If so, what does it say + look like?
 * - Do we show an Intercom chat bubble?
 * - Do we send the error to Sentry for logging?
 */
export function reportError(message: string, options?: ReportErrorOptions) {
  const error = options?.error;

  if (error && process.env.NODE_ENV === "production") {
    console.error(error);
  }

  // Figure out if this is a network error
  // We don't want to send network errors to Sentry because there isn't much we can do about them
  // and they are really noisy.
  const isNetworkError = isErrorNetworkError(error);

  // If we are getting a 4XX error, this is an error that we have crafted a message for
  // and logged on the backend if necessary. We don't need to prompt Intercom or log to Sentry.
  // If we are getting a 0 error it means "undefined error" and we don't care about it.
  let baseSkipSentry = false;
  if (error?.isAxiosError) {
    const status = error?.response?.status;
    baseSkipSentry = (400 <= status && status < 500) || status === 0;

    console.error(
      "Axios Error Info",
      error?.response?.status,
      error?.response?.data
    );
  }

  // Respect the manually specified option, otherwise send all non 4XX, non 0 errors to Sentry
  const skipSentry = options?.skipSentry ?? baseSkipSentry;

  // Send message to Sentry
  if (!skipSentry) {
    Sentry.withScope((scope) => {
      scope.setLevel(isNetworkError ? "warning" : "error");

      if (options?.errorId) {
        scope.setTag("errorId", options?.errorId);
      }

      const payload = options?.payload;
      if (payload) {
        if (options?.error?.isAxiosError) {
          payload.axiosResponse = options?.error?.response?.data;
        }

        scope.setExtras(payload);
      }

      if (options?.error) {
        scope.setExtras({ error: options.error });
      }

      Sentry.captureMessage(message);
    });
  }

  console.error(message, options?.payload);

  if (options?.toast) {
    let toastMessage = "";
    if (isNetworkError) {
      toastMessage =
        "Ouch - looks like there's a network error. Please check your internet connection and try again.";
    } else {
      // If the server response specifies a client message, we show that
      toastMessage =
        options?.error?.response?.data?.message ||
        options?.toast.message ||
        message;

      toastMessage = toastMessage.trim();
    }

    toast.error(toastMessage, {
      id: options.toast.id,
      ...(options.toast.opts || {}),
    });
  }
}
