import { Duration } from "luxon";

export const WEB_HOST =
  process.env.NODE_ENV !== "development"
    ? "https://lu.ma"
    : "http://localhost:5499";

export const GLOW_HOST =
  process.env.NODE_ENV !== "development"
    ? "https://glow.app"
    : "http://localhost:6499";

export const CDN_URL = "https://cdn.lu.ma/";

export const FALLBACK_COUNTRY_CODE = "US";

export const EXTENSION_BIOMETRICS_ENCRYPTED_PASSWORD_TIMEOUT =
  Duration.fromObject({
    weeks: 4,
  });
