export const LUMA_SESSION_COOKIE = "luma.auth-session-key";
export const GLOW_SESSION_COOKIE = "glow.auth-session";
export const SIGNED_IN_STATE_COOKIE = "luma.is-signed-in";

export const HEADER_AUTH_SESSION = "x-luma-auth-session";
export const HEADER_API_KEY = "x-luma-api-key";

export const HEADER_GLOW_AUTH_SESSION = "x-glow-auth-session";

export const HEADER_LOCALE = "x-locale";

export const DEVICE_ID_COOKIE = "luma.did";

export const FIRST_PAGE_COOKIE = "luma.first-page";
export const FIRST_REFERRER_COOKIE = "luma.first-referrer";

// Cache the admin's user cookie to make logging out of the account easy
export const POLYJUICE_ADMIN_COOKIE = "luma.polyjuice.admin-session";
// Boolean to store if the current user is polyjuiced
export const POLYJUICE_SIGN_IN_STATE_COOKIE = "luma.polyjuice.sign-in-state";

// 60 days in ms (60 days * 24 hours * 60 minutes * 60 seconds * 1000 ms)
export const DEFAULT_COOKIE_MAX_AGE = 60 * 24 * 60 * 60 * 1_000;

type CookieConfig = {
  name: string;
  value: string | null;
  attributes: {
    httpOnly?: boolean;
    maxAge?: number;
    secure?: boolean;
    domain?: string;
    sameSite?: "lax";
  };
};

export const createSessionCookieConfig = ({
  domain,
  content,
  maxAge,
  app,
}: {
  domain: string;
  content: string | null;
  maxAge?: number;
  app: "glow-xyz" | "glow-app" | "luma";
}): CookieConfig => {
  const sameSite = "lax";
  const secure = process.env.NODE_ENV === "production";
  return {
    name:
      app === "glow-xyz" || app === "glow-app"
        ? GLOW_SESSION_COOKIE
        : LUMA_SESSION_COOKIE,
    value: content,
    attributes: {
      httpOnly: true,
      maxAge: maxAge ?? DEFAULT_COOKIE_MAX_AGE,
      secure,
      domain,
      sameSite,
    },
  };
};

export const createSignedInStateCookieConfig = ({
  domain,
  content,
  maxAge,
}: {
  domain: string;
  content: string | null;
  maxAge?: number;
}): CookieConfig => {
  return {
    name: SIGNED_IN_STATE_COOKIE,
    value: content,
    attributes: {
      httpOnly: false,
      domain,
      maxAge: maxAge ?? DEFAULT_COOKIE_MAX_AGE,
    },
  };
};
