import { LumaClientType, ZmClientConstructor } from "@luma-team/shared";
import { addGlowIdDict } from "@glow/react/components/glow-id/GlowIdProvider";
import config from "../config";

export const ZmClient = new ZmClientConstructor(config.apiUrl, {
  clientType: LumaClientType.GlowWeb,
  onResponse: (resp) => {
    addGlowIdDict(resp as any);
  },
});
