import { Solana } from "../solana/solana-base";
import { UserSelfInfo } from "./user-api";

export const HEADER_RELEASE_VERSION = "x-luma-release-version"; // eg. 1.2
export const HEADER_BUILD_VERSION = "x-luma-build-version"; // eg. 29

export const GLOW_HEADER_RELEASE_VERSION = "x-glow-release-version"; // eg. 1.2
export const GLOW_HEADER_BUILD_VERSION = "x-glow-build-version"; // eg. 29

export const LUMA_HEADER_CLIENT_TYPE = "x-luma-client-type"; // "glow-chrome-extension" | "glow-ios-app" | "luma-ios-com.luma.studio";
export const LUMA_HEADER_CLIENT_VERSION = "x-luma-client-version"; // eg. 1.0.0
export const LUMA_HEADER_CUSTOM_SOURCE = "x-luma-custom-source"; // utm_source

export enum LumaClientType {
  GlowIOS = "glow-ios-app",
  GlowAndroid = "glow-android-app",
  GlowDesktopExtension = "glow-desktop-extension",
  GlowMobileExtension = "glow-mobile-extension",
  GlowWeb = "glow-web",
  LumaAppAndroid = "luma-app-android",
  LumaAppIos = "luma-app-ios",
  LumaHelp = "luma-help",
  LumaWeb = "luma-web",
  Screenshotter = "screenshotter",
}

export const getPlatformFromClientType = (
  clientType: LumaClientType
): "glow" | "luma" => {
  switch (clientType) {
    case LumaClientType.GlowIOS:
    case LumaClientType.GlowAndroid:
    case LumaClientType.GlowDesktopExtension:
    case LumaClientType.GlowMobileExtension:
    case LumaClientType.GlowWeb:
      return "glow";
    case LumaClientType.LumaWeb:
    case LumaClientType.Screenshotter:
      return "luma";
  }

  // We want:
  // - TS to require switch to be exhaustive,
  // - a fallback in case clientType is something else.
  // This is the fallback which type-wise can't happen,
  // but in reality it can.
  return "luma";
};

export type PingPostApi = {
  "/mobile/ping": {
    request: {};
    response: {
      needs_update: boolean;
      user: UserSelfInfo | null;
      // Used to alert the app that something is happening
      message: string | null;
    };
  };
  "/glow/mobile/ping": {
    request: { client_id: string | null };
    response: {
      needs_update: boolean;
      show_feature_banners: boolean;
      show_top_sites: boolean;
      health: Solana.NetworkHealth;
      tps: number | null;
    };
  };
  "/glow/extension/ping": {
    request: {
      version: string;
      client_id: string | null;
    };
    response: {
      needs_update: boolean;
      health: Solana.NetworkHealth;
      tps: number | null;
    };
  };
};
