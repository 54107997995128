import {
  arrow,
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  Placement,
  safePolygon,
  shift,
  size,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from "@floating-ui/react-dom-interactions";
import CheckIcon from "@lux/icons/Check16.svg";
import PlusIcon from "@lux/icons/feather/plus.svg";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import uniqBy from "lodash/uniqBy";
import React, {
  cloneElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { FRAMER_BOUNCE_TRANSITION, FRAMER_TRANSITION } from "../utils/framer";
import { LuxLink } from "./LuxLink";
import { LuxOverlay } from "./LuxOverlay";

export const LuxBaseMenu = ({
  trigger,
  placement: _placement,
  open,
  setOpen,
  mode = "click",
  delayMs = 0,
  children,
  showArrow = true,
  color = "default",
  shadow = "small",
  triggerWrapperClassName,
  useSafePolygon = true,
  menuZIndex,
}: {
  trigger: JSX.Element;
  placement: Placement;
  open: boolean;
  setOpen: (open: boolean) => void;
  // The difference between click and click-open is that the latter doesn't
  // close the menu when it's open and the trigger is clicked.
  mode?: "click" | "hover" | "click-open";
  delayMs?: number;
  children: JSX.Element;
  showArrow?: boolean;
  color?: "default" | "inverted";
  shadow?: "none" | "small" | "medium";
  triggerWrapperClassName?: string;
  /**
   * Disables "safe polygon" - a dynamic area outside of the component
   * where cursor is allowed to move without triggering "close" event.
   * Useful eg. for spaces where there are adjacent tooltips.
   * https://floating-ui.com/docs/useHover#safepolygon
   * */
  useSafePolygon?: boolean;
  menuZIndex?: React.CSSProperties["zIndex"];
}) => {
  const arrowRef = useRef<HTMLDivElement>(null);
  const {
    context,
    x,
    y,
    strategy,
    middlewareData,
    update,
    floating,
    reference,
    refs,
    placement,
  } = useFloating({
    placement: _placement,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(8),
      flip(),
      shift({ padding: 4 }),
      arrow({
        element: arrowRef,
      }),
      size({
        apply({ availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight}px`,
          });
        },
        padding: 16,
      }),
    ],
  });

  useEffect(() => {
    update();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children, trigger]);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context, {
      enabled: mode === "hover",
      restMs: delayMs,
      handleClose: useSafePolygon ? safePolygon() : null,
    }),
    useFocus(context),
    useRole(context, { role: "tooltip" }),
    useDismiss(context),
  ]);

  const { x: arrowX, y: arrowY } = middlewareData.arrow || {};

  // I'm not sure why `useDismiss` isn't handling this properly...
  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setOpen(false);
      }
    };

    document.addEventListener("keydown", handleEscape);

    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, [mode, setOpen, refs]);

  let shadowStyle = "var(--shadow-sm)";
  if (shadow === "none") {
    shadowStyle = "none";
  } else if (shadow === "medium") {
    shadowStyle = "var(--shadow)";
  }

  const overrideProps: React.HTMLProps<Element> = {
    className: classNames(
      "lux-menu-trigger-wrapper",
      { "cursor-pointer": mode !== "hover" },
      triggerWrapperClassName,
      trigger.props.className
    ),
  };
  if (mode === "click") {
    overrideProps.onClick = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setOpen(!open);
    };
  } else if (mode === "click-open") {
    overrideProps.onClick = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setOpen(true);
    };
  }

  return (
    <>
      {cloneElement(
        trigger,
        getReferenceProps({
          ref: reference,
          ...trigger.props,
          ...overrideProps,
        })
      )}

      <FloatingPortal>
        <AnimatePresence>
          {open && children && (
            <OptionalOverlay
              shouldWrap={mode === "click" || mode === "click-open"}
              onHide={() => setOpen(false)}
            >
              <motion.div
                className={classNames("lux-menu-wrapper", color, mode)}
                ref={floating}
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.9 }}
                transition={{
                  opacity: FRAMER_TRANSITION,
                  scale: FRAMER_BOUNCE_TRANSITION,
                }}
                onMouseDown={(e) => e.stopPropagation()}
                style={{
                  ...scaleOrigin(placement, arrowX, arrowY),
                  position: strategy,
                  top: y ?? 0,
                  left: x ?? 0,
                  zIndex: menuZIndex,
                }}
                {...getFloatingProps()}
              >
                <div
                  className="lux-menu overflow-auto"
                  style={{ boxShadow: shadowStyle }}
                >
                  {children}
                </div>

                {showArrow && (
                  <div
                    className={classNames("lux-menu-arrow", {
                      flipped: placement.startsWith("top"),
                    })}
                    ref={arrowRef}
                    style={{
                      left: arrowX,
                      ...(placement.startsWith("top")
                        ? { bottom: arrowY ?? "-4px" }
                        : { top: arrowY ?? "-4px" }),
                    }}
                  />
                )}
              </motion.div>
            </OptionalOverlay>
          )}
        </AnimatePresence>
      </FloatingPortal>
    </>
  );
};

const OptionalOverlay = ({
  children,
  shouldWrap,
  onHide,
}: {
  children: React.ReactNode;
  shouldWrap: boolean;
  onHide: () => unknown;
}) => {
  if (!shouldWrap) {
    return <>{children}</>;
  }

  return (
    <LuxOverlay onHide={onHide} canClickOutToDismiss={true}>
      {children}
    </LuxOverlay>
  );
};

export type LuxMenuRowData =
  | { type: "divider"; isAccessory: true }
  | { type: "header"; isAccessory: true; name: string }
  | ({
      key: string;
      name: string;
      icon?: React.ReactNode;
      rightText?: React.ReactNode;
      type?: "data";
      isAccessory?: false;
      searchKey?: string;
    } & (
      | {
          href: string;
          onClick?: never | undefined;
        }
      | {
          href?: never | undefined;
          onClick: (() => Promise<void>) | (() => void);
        }
    ));

/**
 * A popover menu.
 *
 * @param trigger The trigger element to anchor the menu to.
 * @param open Whether the menu is open.
 * @param setOpen Set the menu to open / close.
 * @param placement placement of the menu.
 * @param rows Rows in the menu.
 * @param onClick Function called when a menu row is clicked.
 * @param showArrow Whether to show the arrow (tip) that points to the trigger.
 * @param defaultSelectFirst Whether to highlight (select) the first row when
 *    the menu is shown.
 * @param searchable Whether to display a search bar above the rows.
 * @param searchPlaceholder The placeholder for the search bar.
 * @param onCreate Function called when a new element should be created. If this
 *    is null, then creation is not allowed.
 * @param forbiddenCreateValue The list of values to disallow creating. This is
 *    usually existing values. If a value is one of the rows, creating the same
 *    value is disallowed automatically. So only specify additional values.
 */
export const LuxMenu = ({
  trigger,
  open,
  setOpen,
  placement,
  rows: _rows,
  showArrow = true,
  defaultSelectFirst = true,
  searchable = false,
  searchPlaceholder,
  onCreate,
  forbiddenCreateValue,
  minWidth,
  maxWidth,
  selectedKey,
  menuZIndex,
}: {
  trigger: JSX.Element;
  open: boolean;
  setOpen: (open: boolean) => void;
  placement: Placement;
  rows: Array<LuxMenuRowData | null>;
  showArrow?: boolean;
  defaultSelectFirst?: boolean;
  searchable?: boolean;
  searchPlaceholder?: string;
  onCreate?: (value: string) => Promise<unknown> | unknown;
  forbiddenCreateValue?: string[];
  minWidth?: number;
  maxWidth?: number;
  selectedKey?: string;
  menuZIndex?: React.CSSProperties["zIndex"];
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const searchInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (open) {
      // Clear the search and focus the menu when it is opened
      searchInputRef.current?.focus();
      setSearchTerm("");
      setCurrentIndex(defaultSelectFirst ? 0 : -1);
    }
  }, [open, setSearchTerm, setCurrentIndex, defaultSelectFirst]);

  useEffect(() => {
    // When we have a new search term, we put the selected value at the start
    setCurrentIndex(0);
  }, [searchTerm, setCurrentIndex]);

  // Search
  const lowerSearchTerm = searchTerm.trim().toLowerCase();
  let filteredRows = _rows.filter((r) => r != null) as Array<LuxMenuRowData>;
  let hasExactMatch = false;
  if (lowerSearchTerm) {
    filteredRows = _rows.filter((r) => {
      if (r == null || r.type === "divider") {
        return false;
      }

      const lowerName = r.name.toLowerCase();
      if (lowerName === lowerSearchTerm) {
        hasExactMatch = true;
        return true;
      }

      return lowerName.match(new RegExp("\\b" + lowerSearchTerm));
    }) as Array<LuxMenuRowData>;

    if (
      filteredRows.length > 0 &&
      !filteredRows[0].isAccessory &&
      filteredRows[0].searchKey
    ) {
      filteredRows = uniqBy(filteredRows, (r) =>
        r.isAccessory ? "" : r.searchKey
      );
    }
  }

  let canCreate = Boolean(onCreate && lowerSearchTerm && !hasExactMatch);
  if (canCreate && forbiddenCreateValue) {
    canCreate = !forbiddenCreateValue
      .map((v) => v.toLowerCase())
      .includes(lowerSearchTerm);
  }

  const decrementIdx = useCallback(() => {
    setCurrentIndex((currIdx) => {
      const minIdx = 0;
      let nextIdx = Math.max(currIdx - 1, minIdx);

      // Skip over accessory views
      while (nextIdx > minIdx && filteredRows[nextIdx].isAccessory) {
        nextIdx--;
      }

      return nextIdx;
    });
  }, [setCurrentIndex, filteredRows]);

  const incrementIndex = useCallback(() => {
    setCurrentIndex((currIdx) => {
      const maxIdx = canCreate ? filteredRows.length : filteredRows.length - 1;
      let nextIdx = Math.min(currIdx + 1, maxIdx);

      // Skip over accessory views
      while (nextIdx < maxIdx && filteredRows[nextIdx].isAccessory) {
        nextIdx++;
      }

      return nextIdx;
    });
  }, [setCurrentIndex, canCreate, filteredRows]);

  // Keyboard navigation
  const handleKeyNavigation = useCallback(
    // This function needs to be synchronous in order to properly prevent default and
    // stop propagation on the keyboard event.
    (event: KeyboardEvent): boolean => {
      if (!open) {
        return true;
      }

      switch (event.key) {
        case "ArrowDown":
        case "Down": {
          event.preventDefault();
          event.stopPropagation();
          incrementIndex();
          return false;
        }
        case "ArrowUp":
        case "Up": {
          event.stopPropagation();
          event.preventDefault();
          decrementIdx();
          return false;
        }
        case "Enter": {
          event.preventDefault();
          event.stopPropagation();

          if (currentIndex > filteredRows.length || currentIndex < 0) {
            return false;
          }

          if (currentIndex === filteredRows.length) {
            if (canCreate) {
              onCreate?.(searchTerm);
              setSearchTerm("");
            }
            return false;
          }

          // Find the row and trigger the action.
          const r = filteredRows[currentIndex];

          if (r.isAccessory) {
            return false;
          }

          if (r.href) {
            window.location.href = r.href;
          }
          if (r.onClick) {
            r.onClick();
          }

          setOpen(false);
          return false;
        }
      }

      return true;
    },
    [
      open,
      setOpen,
      incrementIndex,
      decrementIdx,
      canCreate,
      filteredRows,
      currentIndex,
      searchTerm,
      onCreate,
    ]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyNavigation);
    return () => {
      document.removeEventListener("keydown", handleKeyNavigation);
    };
  }, [handleKeyNavigation]);

  return (
    <LuxBaseMenu
      trigger={trigger}
      placement={placement}
      open={open}
      setOpen={setOpen}
      showArrow={showArrow}
      menuZIndex={menuZIndex}
    >
      <>
        <div className="lux-menu-content" style={{ minWidth, maxWidth }}>
          {searchable && (
            <div className="lux-menu-search-wrapper">
              <input
                className="with-placeholder"
                // @ts-ignore
                ref={searchInputRef}
                type="text"
                placeholder={searchPlaceholder || "Search"}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          )}

          {(filteredRows.length > 0 ||
            canCreate ||
            searchTerm ||
            !searchable) && (
            <div className="rows overflow-auto">
              {filteredRows.map((row, i) => {
                if (row.type === "divider") {
                  const prevRow = filteredRows[i - 1];
                  if (prevRow && prevRow.type === "divider") {
                    return null;
                  }

                  return (
                    <div key={`divider-${i}`} className="lux-menu-divider" />
                  );
                }

                if (row.type === "header") {
                  return (
                    <div key={`header-${i}`} className="lux-menu-header">
                      {row.name}
                    </div>
                  );
                }

                return row.href ? (
                  <LuxLink key={row.key} href={row.href}>
                    <MenuItem
                      row={row}
                      focused={i === currentIndex}
                      selectedKey={selectedKey}
                      onHover={() => {
                        setCurrentIndex(i);
                      }}
                    />
                  </LuxLink>
                ) : (
                  <div
                    key={row.key}
                    onClick={async (e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setOpen(false);
                      if (row.onClick) {
                        await row.onClick();
                      }
                    }}
                  >
                    <MenuItem
                      row={row}
                      focused={i === currentIndex}
                      selectedKey={selectedKey}
                      onHover={() => {
                        setCurrentIndex(i);
                      }}
                    />
                  </div>
                );
              })}
              {filteredRows.length === 0 && searchTerm && !canCreate && (
                <div className="no-result">No Results</div>
              )}
              {filteredRows.length === 0 && !searchable && (
                <div className="no-result">No Options</div>
              )}
              {canCreate && (
                <div
                  className={classNames("create-row flex-center", {
                    selected: currentIndex === filteredRows.length,
                  })}
                  onClick={async () => {
                    await onCreate?.(searchTerm);
                    setSearchTerm("");
                  }}
                >
                  <div className="icon flex-center">
                    <PlusIcon />
                  </div>
                  <div>
                    Create "<span>{searchTerm}</span>"
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </>
    </LuxBaseMenu>
  );
};

const MenuItem = ({
  row,
  focused,
  selectedKey,
  onHover,
}: {
  row: LuxMenuRowData & { type?: "data" };
  focused: boolean;
  selectedKey?: string;
  onHover: () => void;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const selected = selectedKey === row.key;
  const icon =
    selected || (selectedKey && !row.icon) ? <CheckIcon /> : row.icon;

  // When using keyboard navigation, make sure the focused item is visible.
  useEffect(() => {
    if (focused) {
      // When the menu is first mounted it's in the top left of the screen.
      // Then the trigger position is measured and the menu is positioned correctly.
      // We want to wait until the menu is positioned before scrolling to it.
      setTimeout(() =>
        ref.current?.scrollIntoView({ block: "nearest", inline: "start" })
      );
    }
  }, [focused]);

  return (
    <div
      ref={ref}
      className={classNames("lux-menu-item flex-center spread", {
        focused,
        selected,
        "has-icon": row.icon,
      })}
      onMouseEnter={onHover}
    >
      <div className="flex-center icon-text">
        {icon && <div className="menu-icon flex-center">{icon}</div>}
        <div className="menu-text flex-1">{row.name}</div>
      </div>

      {row.rightText && (
        <div className="menu-right-text mono-number">{row.rightText}</div>
      )}
    </div>
  );
};

const scaleOrigin = (
  placement: Placement,
  x: number | null | undefined,
  y: number | null | undefined
): { originX: number | string; originY: number | string } => {
  const xInPixels = x ? `${x}px` : null;
  const yInPixels = y ? `${y}px` : null;

  if (placement === "top") {
    return { originX: xInPixels || 0.5, originY: 1 };
  }

  if (placement === "top-start" || placement === "right-end") {
    return { originX: xInPixels || 0, originY: yInPixels || 1 };
  }

  if (placement === "top-end" || placement === "left-end") {
    return { originX: xInPixels || 1, originY: yInPixels || 1 };
  }

  if (placement === "bottom") {
    return { originX: xInPixels || 0.5, originY: 0 };
  }

  if (placement === "bottom-start" || placement === "right-start") {
    return { originX: xInPixels || 0, originY: yInPixels || 0 };
  }

  if (placement === "bottom-end" || placement === "left-start") {
    return { originX: xInPixels || 1, originY: yInPixels || 0 };
  }

  if (placement === "right") {
    return { originX: 0, originY: yInPixels || 0.5 };
  }

  if (placement === "left") {
    return { originX: 1, originY: yInPixels || 0.5 };
  }

  return { originX: xInPixels || 0, originY: yInPixels || 0 };
};
