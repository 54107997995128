import { Footer, FooterProps } from "@components/Footer";
import { SolanaNav } from "@components/nav/SolanaNav";
import { ResponsiveBreakpoint } from "@utils/StyleConstants";
import Head from "next/head";
import React from "react";

export const SolanaPage = ({
  title,
  children,
  footer = true,
}: {
  title: string;
  children: React.ReactNode;
  footer?: boolean | FooterProps | null | undefined;
}) => {
  const footerProps = typeof footer === "object" ? footer : {};

  return (
    <div className="solana-page">
      <Head>
        <title>{`${title} — Glow`}</title>
      </Head>

      <SolanaNav />

      <div className="solana-content">{children}</div>

      {footer && <Footer {...footerProps} />}

      <style jsx global>{`
        :global(body) {
          --max-width: 1200px;
          --nav-height: 4rem;
          --nav-vertical-padding: 1.25rem;
          --side-padding: 2rem;
          --sidebar-width: 260px;

          @media (max-width: ${ResponsiveBreakpoint.small}) {
            --nav-height: 3.5rem;
            --nav-vertical-padding: 1rem;
            --side-padding: 1.5rem;
          }
        }

        .solana-page {
          .solana-content {
            // footer always at bottom
            min-height: calc(100vh - (16rem - 4rem));
          }

          h1 {
            font-size: 2.5rem;
          }

          h2 {
            font-size: 1.75rem;
            margin-bottom: 1.5rem;
          }

          .subtitle {
            margin-top: -0.75rem;
            margin-bottom: 1.5rem;
            font-size: 1.5rem;
            font-weight: var(--font-weight-medium);
            color: var(--tertiary-color);
          }

          @media (max-width: ${ResponsiveBreakpoint.large}) {
            h1 {
              font-size: 2rem;
            }
          }

          @media (max-width: ${ResponsiveBreakpoint.small}) {
            h1 {
              font-size: 1.75rem;
            }

            h2 {
              font-size: 1.5rem;
              margin-bottom: 1rem;
            }
          }

          @media (max-width: ${ResponsiveBreakpoint.tiny}) {
            h1 {
              font-size: 1.5rem;
            }

            h2 {
              font-size: 1.25rem;
            }

            .subtitle {
              font-size: 1.25rem;
            }
          }
        }
      `}</style>
    </div>
  );
};
