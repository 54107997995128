export const BASE_IGNORED_ERRORS_WEB = [
  // https://sentry.io/organizations/lu-ma/issues/3235669942/
  "Minified React error #423;",
  // https://sentry.io/organizations/lu-ma/issues/3235670049/
  "Minified React error #418;",
  // https://sentry.io/organizations/lu-ma/issues/3235668596/
  "Minified React error #425;",
  new RegExp("Unexpected token {", "i"), // Common android error
  // https://sentry.io/organizations/lu-ma/issues/3691205397/
  // We don't send beacons so we don't care if it fails
  "sendBeacon",
];
