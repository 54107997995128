import { useIsMounted } from "@lux/atoms/hooks/useIsMounted";
import { FRAMER_BOUNCE_TRANSITION, FRAMER_EASE, FRAMER_TRANSITION } from "@lux/atoms/utils/framer";
import { ResponsiveBreakpoint } from "@utils/StyleConstants";
import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect } from "react";
import ReactDOM from "react-dom";

export const SearchOverlay = ({
  onHide,
  children,
}: {
  onHide: () => void;
  children: React.ReactNode | null;
}) => {
  const shouldShow = Boolean(children);

  useEffect(() => {
    if (shouldShow) {
      document.body.classList.add("scroll-locked");
    } else {
      document.body.classList.remove("scroll-locked");
    }

    return () => {
      document.body.classList.remove("scroll-locked");
    };
  }, [shouldShow]);

  useEffect(() => {
    document.addEventListener("keydown", handleEscape);
    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEscape = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      onHide();
    }
  };
  const mounted = useIsMounted();

  return (
    <>
      {mounted &&
        ReactDOM.createPortal(
          <AnimatePresence>
            {Boolean(children) && (
              <SearchModalOverlay onHide={onHide}>
                <SearchModal>{children}</SearchModal>
              </SearchModalOverlay>
            )}
          </AnimatePresence>,
          document.body
        )}

      <style jsx>{`
        :global(#__next) {
          // Create a stacking context on next so that the modal is in front of everything
          position: relative;
          z-index: 1;
        }
      `}</style>
    </>
  );
};

const SearchModal = ({ children }: { children: React.ReactNode }) => {
  return (
    <motion.div
      className={"search-modal"}
      onClick={(e) => {
        e.stopPropagation();
      }}
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.9 }}
      transition={{
        opacity: FRAMER_TRANSITION,
        scale: FRAMER_BOUNCE_TRANSITION,
      }}
    >
      <div className={"search-modal-body overflow-auto"}>{children}</div>

      <style jsx global>{`
        .search-modal {
          overflow: hidden;
          display: flex;
          flex-direction: column;

          width: 480px;

          border-radius: var(--border-radius);
          box-shadow: var(--shadow-lg);
          background-color: var(--modal-bg-color);
          backdrop-filter: blur(5px);

          .search-modal-body {
            width: 100%;
          }
        }
      `}</style>
    </motion.div>
  );
};

const SearchModalOverlay = ({
  children,
  onHide,
}: {
  children: React.ReactNode;
  onHide: () => void;
}) => {
  return (
    <motion.div
      className={"search-modal-overlay"}
      onClick={onHide}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        duration: 0.3,
        opacity: { ease: FRAMER_EASE },
      }}
    >
      {children}
      <style jsx global>{`
        .search-modal-overlay {
          position: fixed;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          padding-top: 15vh;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          overflow: hidden;
          z-index: 900;

          background: var(--overlay-color);

          @keyframes pop {
            50% {
              transform-origin: center;
              transform: scale(1.005);
            }
          }

          @media (max-width: ${ResponsiveBreakpoint.small}) {
            padding: 2rem;
          }

          @media (max-width: ${ResponsiveBreakpoint.tiny}) {
            padding: 1.5rem;
          }
        }
      `}</style>
    </motion.div>
  );
};
