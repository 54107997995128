import React from "react";
import { useFormikContext } from "formik";
import lodashSize from "lodash/size";
import { LuxButton } from "./LuxButton";

export type LuxSubmitButtonProps = {
  className?: string;
  outline?: boolean;
  disabledWhenErrorBeforeSubmit?: boolean;
} & Omit<
  React.ComponentPropsWithoutRef<typeof LuxButton>,
  "should_submit" | "loading"
>;

export const LuxSubmitButton = ({
  label,
  icon,
  fullWidth,
  size = "medium",
  iconPlacement = "left",
  color = "primary",
  variant = "solid",
  className,
  rounded,
  disabled,
  disabledWhenErrorBeforeSubmit,
}: LuxSubmitButtonProps) => {
  const { errors, submitCount, isSubmitting } = useFormikContext();
  const hasError =
    (submitCount > 0 || disabledWhenErrorBeforeSubmit) &&
    lodashSize(errors) > 0;

  // Note: We don't call submitForm on onClick because Firefox will trigger both onClick
  // and the native form submit events which will result in a double submit of the form
  return (
    <LuxButton
      label={label}
      icon={icon}
      fullWidth={fullWidth}
      size={size}
      iconPlacement={iconPlacement}
      color={color}
      className={className}
      variant={variant}
      rounded={rounded}
      disabled={disabled || isSubmitting || hasError}
      loading={isSubmitting}
      shouldSubmit={true}
    />
  );
};
