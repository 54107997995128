import { useSyncExternalStore } from "react";

/**
 * - if hook is rendered on server - return false
 * - if the tree is being hydrated the first time -
 *   still return false (so there's no hydration mismatch)
 * - after the tree has been hydrated or if hook is rendered
 *   first time on the client - return true
 *
 * We use this hook to run actions after the first load. This also lets us run code on the client
 * that will not run on the server (because the server does not mount components).
 */
export const useIsMounted = (): boolean => {
  const isMounted = useSyncExternalStore(
    subscribe,
    getClientSnapshot,
    getServerSnapshot
  );
  return isMounted;
};

const subscribe = () => {
  return () => null;
};

const getClientSnapshot = () => {
  return true;
};

const getServerSnapshot = () => {
  return false;
};
